import React from "react";
import {LIST_FIELD_TYPE, MAX_FILE_SIZE, NUMBER_TO_MONTHS} from "../constants/hardCodedConstants";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../consumers/httpRequiestsUtils";
import {toast} from "react-toastify";
import qs from "qs";

import ReactTooltip from "react-tooltip";

export const obtenerElementPorId = (id) => {
    return document.getElementById(id);
};

export const jsonNoEstaVacio = (json) => {
    return !(json && Object.keys(json).length === 0 && json.constructor === Object);
};

export const jsonEstaVacio = (filaJson) => {
    return (filaJson && Object.keys(filaJson).length === 0 && filaJson.constructor === Object);
};

export const obtenerValorDeElementPorId = (id) => {
    try {
        let element = obtenerElementPorId(id);
        if (element) {
            return (element.matches('[type="checkbox"]')) ? element.checked : element.value;
        } else {
            console.warn(`no existe elemento con el id ${id}`);
        }

    } catch (e) {
        console.error(`obtenerValorDeElementPorId id: ${id}`, e)
    }

};

export const obtenerExtensionDeArchivo = (fileName) => {
    let regex = /(?:\.([^.]+))?$/;
    return regex.exec(fileName)[1].toLocaleLowerCase();
};

export const stringSeEncuentraEnArray = (string, array) => {
    if (array == null || array == undefined) {
        console.warn("array es undefined")
    }
    return (array && array.indexOf(string) > -1) ? true : false;
};

export const responseUploadFileSuccess = (response) => {
    return (response && response.status && response.status == 200);
};

export const obtenerArchivoDeEvent = (event) => {
    if (event.target.files && event.target.files[0]) {
        return event.target.files[0];
    }
};

export const parseExtensionesToString = (extensionesArray) => {
    let extensionesConPunto = extensionesArray.map((extension) => "." + extension);
    return extensionesConPunto.join(',');
};

export const extensionYtamañoDeArchivoEsValido = (file, extensionesValidas) => {
    let extension = obtenerExtensionDeArchivo(file.name);
    return stringSeEncuentraEnArray(extension, extensionesValidas) && file.size <= MAX_FILE_SIZE;
};

export const extensionTamañoDeArchivoValido = (file, extensionesValidas, maxFileSize) => {
    let extension = obtenerExtensionDeArchivo(file.name);
    return stringSeEncuentraEnArray(extension, extensionesValidas) && file.size <= maxFileSize;
};

export const resetForm = (formId) => {
    document.getElementById(formId).reset();
};

export const agregarStringAArraydDeStringsSiNoExiste = (string, array) => {
    let newArray = array;
    if (!stringSeEncuentraEnArray(string, array)) {
        newArray = [...array, string];
    }
    return newArray;
};

export const eliminarStringDeArraydDeStrings = (string, array) => {
    let newArray = array;
    if (stringSeEncuentraEnArray(string, array)) {
        let index = array.indexOf(string);
        newArray.splice(index, 1);
    }
    return newArray;
};

export const stringEndsInThisCharacter = (string, character) => {
    try {
        return (string[(string.length - 1)] == character) ? true : false;
    } catch (e) {
        console.error(`stringEndsInThisCharacter error, string: "${string}", character: "${character}". Returning false`);
        return false;
    }
};

export const notNullAndNotUndefinded = (data) => {
    return (data != null && data != undefined) ? true : false;
};

export const isNullOrUndefinded = (data) => {
    return (data == null || data == undefined) ? true : false;
};


export const hideElement = (inputCompleteId) => {
    let element = document.getElementById(inputCompleteId);
    if (element) {
        element.setAttribute("hidden", "");
    } else {
        console.warn(`no se encontró elemento:  "${inputCompleteId}"`);
    }
};

export const showElement = (inputCompleteId) => {
    let element = document.getElementById(inputCompleteId);
    if (element) {
        element.removeAttribute("hidden");
    } else {
        console.warn(`no se encontró elemento:  "${inputCompleteId}"`);
    }
};

export const setAttributeToElement = (element, attributeStringName, attributeStringValue) => {
    if (notNullAndNotUndefinded(element)) {
        element.setAttribute(attributeStringName, attributeStringValue);
    }
};

export const removeAttributeToElement = (element, attributeString) => {
    if (notNullAndNotUndefinded(element)) {
        element.removeAttribute(attributeString);
    }
};

export const getElementByQuerySelector = (querySelectorString) => {
    let element = document.querySelector(querySelectorString);

    if (isNullOrUndefinded(element)) {
        element = document.querySelector(querySelectorString);
    }
    return element
};

export const convertirArrayToStringSeparadoPorComas = (array) => {
    let string = "";
    let index = 0;
    for (let element of array) {
        let separador = (index == array.length - 1) ? "" : ",";
        string = string + element + separador;
        index++;
    }
    return string;
};

export const convertirStringToArraySeparadoPorComas = (string) => {
    console.debug("convertirStringToArraySeparadoPorComas, string: ", string);
    let array = [];
    if (notNullAndNotUndefinded(string) && string != "") {
        let arrayOfStrings = string.split(",");
        array = arrayOfStrings.map(item => parseInt(item))
    }
    return array;
};

export const isTrue = (data) => {
    return (data === true);
};

export const mapDropDownOptionsReservaFromBackendToSelectOptionsNeededForLibrary = (backEndOptions, idEntity = "IdEntity") => {
    return backEndOptions.map((option, index) => ({value: option.Value, label: option.Text, idEntity: option?.IdEntity, "aforo": option?.Aforo}));
};

export const mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary = (backEndOptions, idEntity = "IdEntity") => {
    return backEndOptions.map((option, index) => ({value: option.Value, label: option.Text, idEntity: option?.IdEntity }));
};

export const mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric = (backEndOptions, valueTextKeyName, labelTextKeyName) => {
    return backEndOptions.map((option) => ({value: option[valueTextKeyName], label: option[labelTextKeyName], ...option}));
};

export const mapDropDownOptionsWithDescriptionFromBackendToSelectOptionsNeededForLibraryGeneric = (backEndOptions, valueTextKeyName, labelTextKeyName) => {
    return backEndOptions.map((option, index) => ({value: option[valueTextKeyName], label:(
            <>
                <p data-tip data-for={index+labelTextKeyName} className="d-block mb-0">
                    {option[labelTextKeyName]}
                </p>

                {option["Description"] &&
                <ReactTooltip id={index+labelTextKeyName} place="top" effect="solid" className="tooltip-style-orange text-center">
                    {option["Description"]}
                </ReactTooltip>}
            </>
        ), ...option}));
};

export const getKeyArrayOfJson = (jsonn) => {
    if (notNullAndNotUndefinded(jsonn) && jsonNoEstaVacio(jsonn)) {
        return Object.keys(jsonn);
    }
    return [];
};

export const clearInputValue = (htmlElementId) => {
    try {
        let element = obtenerElementPorId(htmlElementId);
        if (element) {
            console.debug("clean input file: ", {htmlElementId});
            element.value = '';
        } else {
            console.warn(`no existe elemento con el id ${htmlElementId}`);
        }

    } catch (e) {
        console.error(`clearInputValue id: ${htmlElementId}`, e)
    }
};

export const obtenerPaisPeru = (paisesResponse) => {
    if (responseCode200(paisesResponse)) {
        let paises = paisesResponse.data;
        let paisPeruArray = paises.filter((pais) => pais["EsLocal"] === true);
        if (paisPeruArray.length === 1) {
            let peruJson = paisPeruArray[0];
            return peruJson["Value"]
        } else {
            console.warn("no se encontró país perú en getPaises")
        }
    } else {
        mostrarMensajeDeErrorConToast(paisesResponse);
    }
};

export const getTextOfSelectedValueFromSelectHTMLElement = (element) => {
    try {
        return element.options[element.selectedIndex].text;
    } catch (e) {
        console.warn("no se pudo obtener el text del select input ", element);
        return "";
    }
};

export const agregarTextOfSelectInputType = (keyField, element, payload) => {
    console.debug(`key field: '${keyField}' es de tipo: '${LIST_FIELD_TYPE}'`);
    let descripcionKey = keyField.replace("Id", "");
    console.debug("descripcionKey: ", descripcionKey);
    let text = getTextOfSelectedValueFromSelectHTMLElement(element);
    return {...payload, [descripcionKey]: text};
};

export const isEmpty = (str) => {
    return (!str || str.length === 0);
};

export const parseStringToDate = (stringDate) => {//2014-04-03
    let parts =stringDate.split('-');
    // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
    // January - 0, February - 1, etc.
    let mydate = new Date(parts[0], parts[1] - 1, parts[2]); 
    //console.log(mydate.toDateString());
    return mydate;
  }

export const getCurrentDay = (date = getTodayDate()) => {
    let today = date;
    let dd = String(today.getDate()).padStart(2, '0');
    console.debug("current day ", {dd});
    return dd;
};

export const getCurrentMonth = (date = getTodayDate()) => {
    let today = date;
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    console.debug("current month ", {mm});
    return mm;
};

export const getCurrentYear = (date = getTodayDate()) => {
    let today = date;
    let yyyy = today.getFullYear();
    return yyyy;
};

export const getCurrentDate = (date = getTodayDate()) => {
    return `${getCurrentYear(date)}-${getCurrentMonth(date)}-${getCurrentDay(date)}`;
}

export const getTomorrowsDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow;
};

export const getTodayDate = () => {
    return new Date();
};

export const isCurrentDayGreaterThanNumber = (number) => {
    return new Date().getDate() > number;
}

export const addDays = (date, days) => {
    date.setDate(date.getDate() + days)
    return date;
};

export const toIsoForInput = (date) => {
    return date?.toISOString()?.split("T")[0] || null;
}

export const toIsoStringForInput = (stringDate) => {
    return stringDate?.split("T")[0] || null;
}

export const calculateDaysInBetween = (startDate, endDate) => {
    const TIME_STAMP = "T00:00:00";
    let start_Date = new Date(startDate+TIME_STAMP);
    let end_Date = new Date(endDate+TIME_STAMP);
    let timeInBetween = end_Date.getTime() - start_Date.getTime();
    let daysInBetween = (timeInBetween / (1000 * 3600 * 24)) + 1;
    return daysInBetween
}

export const generateDateArray = (startDate, endDate) => {
    const TIME_STAMP = "T00:00:00";
    const dateArray = [];
    const currentDate = new Date(startDate+TIME_STAMP);
    const lastDate = new Date(endDate+TIME_STAMP);
    for (let date = currentDate; date <= lastDate; date.setDate(date.getDate() + 1)) {
        dateArray.push(new Date(date));
    }
    return dateArray;
}

export const formatStringDate = (inputDate) => {
    const [year, month, day] = inputDate.split('-');
    const formattedDate = `${day} / ${NUMBER_TO_MONTHS[month]} / ${year}`;
    return formattedDate;
};

export const getOneYearAfterDate = (inputDate) => {
    const [year, month, day] = inputDate.split('-');
    const date = new Date(year, month - 1, day);
    date.setFullYear(date.getFullYear() + 1);
    return toIsoForInput(date);
}

export const validarFormulario = (contenedor, refContainer, setValidator) => {
    let fields = refContainer.current.querySelectorAll(contenedor);
    let validation = {};
    setValidator(validation);
    fields.forEach(element => {
        if (isEmpty(element.value)) {
            validation[element.name] = ['El campo es obligatorio']
        }
    });
    setValidator(validation);
    return jsonEstaVacio(validation);
};


export const esArrayVacio = (array, message = "Seleccionar al menos un item", mostrarToast = true) => {
    let esArrayVacioo = (array.length > 0) ? false : true;
    if (esArrayVacioo && mostrarToast) {
        toast.warn(message);
    }
    return esArrayVacioo;
};

export const esValidacionCorrectaRegex = (regex, valor, messageError = "La validación es incorrecta") => {
    if (regex) {
        let regexExp = new RegExp(regex);
        if (!regexExp.test(valor)) {
            toast.warn(messageError);
            return false;
        }
        return true;
    }
    return false;
};

export const getFormattedName = function (filename, totalLength = 30) {
    if (filename && filename.length > 25) {
        let dots = "...";
        let extension = obtenerExtensionDeArchivo(filename);
        let nameLength = totalLength - dots.length - extension.length;

        return filename.slice(0, nameLength) + "..." + extension;
    }
    return filename;
};

export const shorterName = function(name, maxLength) {
    if(name.length > maxLength){
        const shortedName = name.slice(0, maxLength);
        return `${shortedName}...`;
    }
    return name;
}

export const parseDateInputStringValueToDate = function (stringDateValue) {
    let array = stringDateValue.split(/\D/);
    return new Date(array[0], --array[1], array[2]);
};

export const scrollToElement = function (htmlSelectorElement) {
    try {
        let element = document.querySelector(htmlSelectorElement);
        element && element.scrollIntoView();
    } catch (e) {
        console.error(e);
    }
};

export const formatNumber = function (number) {
    if (number.toString().length === 1) {
        return "0" + number;
    }
    return number.toString();
};

export const onKeyPressEnter = (e, action) => {
    if (e.key === 'Enter') {
        action();
    }
}

export const setStateWithCallBackResponse = async (callbackAPI, setState, isSelectedOptions = false) => {
    const response = await callbackAPI();
    if (responseCode200(response)) {
        var dataMap = isSelectedOptions ? mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(response.data) : response.data;
        setState(dataMap);
    } else {
        mostrarMensajeDeErrorConToast(response);
    }
};


export const manageApiResponse = async (response, callbackSuccess) => {
    if (responseCode200(response)) {
        toast.success(response?.data?.Message);
        callbackSuccess(response);
    } else {
        mostrarMensajeDeErrorConToast(response);
    }
};

export const callCallbackAPIGeneric = async (callbackAPI, calbackSuccess) => {
    const response = await callbackAPI();
    if (responseCode200(response)) {
        calbackSuccess(response.data);
    } else {
        mostrarMensajeDeErrorConToast(response);
    }
};

export const focusElement = (event) => {
    let element = event.target;
    element.scrollIntoView({ block: "start", behavior: "smooth" });
}

export const getQueryParams = (locationSearch) => {
    return qs.parse(locationSearch, { ignoreQueryPrefix: true });
};

export const usuarioLogueadoTieneAlgunoDeLosSiguientesRoles = function (loggedInUserRolesIds, roleIdsArrayToCheck) {
    for (let roleId of loggedInUserRolesIds) {
        if (roleIdsArrayToCheck.includes(roleId)) {
            return true;
        }
    }
    return false;
}

export const initialAllOptionPromises = async (allPromisesArray, ID_TYPES_Array, setAllOptions) => {
    setAllOptions([]);
    let array = [];
    await Promise.allSettled(allPromisesArray())
           .then(allOptions => {
                allOptions.map((option, index) => {
                    if(option.status === "fulfilled"){
                        let key = ID_TYPES_Array[index];
                        let value = option.value?.data ?? option.value;
                        array.push({key, value});
                    }
                })
           }).finally(() => {
                setAllOptions(array);
           });
}

export const updateFiltersWithExtraData = (allOptions, extraData, setFilterOptions) => {
    let newData = allOptions.map(o => {
        if(extraData[o.key]){
            let value = o.value.filter(x => extraData[o.key]?.includes(x.Value));
            return {key: o.key, value: value}
        }
        return {key: o.key, value: o.value};
    });
    setFilterOptions(newData);
};

export const cleanDropdown = (setFormValues, keys) => {
    return keys?.map(o => setFormValues(lastData => ({...lastData, [o]: null})));
};

export const handleFind = (allDropdowns, key) => {
    return allDropdowns?.find(o => o.key == key)?.value;
};

export const handleFindDropdownSelected = (allDropdowns, res, key) => {
    return allDropdowns?.find(o => o.key == key)?.value?.find(o => o.value == res[key]);
}

export const handleFindDropdownMultiSelected = (allDropdowns, res, key) => {
    return allDropdowns?.find(o => o.key == key)?.value?.filter(o => res[key]?.includes(o.value));
}

export const handleChangeSelect = (setFormValues, res, key) => {
    setFormValues(lastData => ({...lastData, [key]: res.target?.value?.value ?? res.target?.value}));
}