import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

const GenericTooltipWithButton = ({id, tooltipText, buttonText, buttonFunction}) => {
    return (
        <>
            <p data-tip data-for={id} className="d-inline">
                <FontAwesomeIcon icon='info-circle'className="hand-pointer blue-standard-color ml-1 mr-1"/>
            </p>
            <ReactTooltip id={id} place="top" effect="solid" className="tooltip-style tooltip-change-witdh tooltip-with-buttons"
             getContent={function () {
                return (
                    <div>
                        <div><span>{tooltipText}</span></div>
                        <div>
                            <button className="tooltip-button" onClick={(e) => {
                                e.preventDefault();
                                buttonFunction();
                            }
                            }>
                                {buttonText}
                            </button>
                        </div>
                    </div>
                )
            }}
            /> 
        </>
    )
}

export default GenericTooltipWithButton;