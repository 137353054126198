import React, {useEffect, useContext, useState} from 'react';
import 'react-whatsapp-widget/dist/index.css'
import ReactTooltip from "react-tooltip";
import { useParams } from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import DatosPersonales from '../DatosPersonales/DatosPersonales';
import {AuthContext} from '../../hooks/context/authContext';
import DatosDeNacimiento from "../datosDeNacimiento/DatosDeNacimiento";
import DatosDeResidencia from "../datosDeResidencia/DatosDeResidencia";
import InformacionContactoEmergencia from "../datosContactoEmergencia/InformacionContactoEmergencia";
import DatosFamiliares from "../datosDeFamiliares/DatosFamiliares";
import DatosEmpresa from "../DatosEmpresa/DatosEmpresa";
import DatosExperienciaLaboral from "../DatosExperienciaLaboral/DatosExperienciaLaboral"
import {MENUS_FICHA_DATOS} from "../../constants/hardCodedConstants";
import FichaDetalleEmpleado from "./FichaDetalleEmpleado";
import ModalSolicitarCambios from "./ModalSolicitarCambios";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import FichaDatosSeccionTitleMessage from "./FichaDatosSeccionTitleMessage";
import ModalInformativo from "./Modales/ModalInformativo";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {
    getDataEmpleadoAPI, getDatosNacimientoAPI, getFichaFormsAPI,
    getSiMostrarModalInformativoAPI, puedoVerEstaFichaApiCallAPI, getGestionFichaDatos, getSeccionesByIdFormAPI, puedoEditarSeccionFormacionAcademicaCallAPI, puedoEditarSeccionExperienciaLaboralCallAPI
} from "../../consumers/backendApisUrls";
import {isNullOrUndefinded} from "../../helper/utils";
import SeccionCierreDeAnio from "../seccionCierreDeAnio/SeccionCierreDeAnio";
import PageHeader from "../../components/pageHeader/PageHeader";
import TipoInstruccion from '../datosFormacionAcademica/tipoInstruccion/TipoInstruccion';
import FormacionProfesional from '../datosFormacionAcademica/formacionProfesional/FormacionProfesional';
import EstudiosAdicionales from '../estudiosAdicionales/EstudiosAdicionales';
import ListarEmpleadoIdiomas from "../datosEmpleadoIdioma/ListarEmpleadoIdiomas";
import DatosAsignacionFamiliar from '../datosDeFamiliares/DatosAsignacionFamiliar';
import { mensajeSeccionCompletada, mensajeSeccionFaltaCompletar } from '../../constants/messages';
import ModalEnabled from '../../components/modalComponents/ModalEnabled';
import { useManageGetRequest } from '../../hooks/useManageGetRequest/useManageGetRequest';

const FichaDatos = () => {
    const {state} = useContext(AuthContext);
    const [empleadoId, setEmpleadoId] = useState();
    const params = useParams();

    const [seccionDatosPersonales, setSeccionDatosPersonales] = useState({});
    const [seccionDatosDeNacimiento, setSeccionDatosDeNacimiento] = useState({});
    const [seccionInformacionDeResidencia, setSeccionInformacionDeResidencia] = useState({});
    const [seccionContactoEmergencia, setSeccionContactoEmergencia] = useState({});
    const [seccionDatosFamiliares, setSeccionDatosFamiliares] = useState({});
    const [seccionAsignacionFamiliar, setSeccionAsignacionFamiliar] = useState({});
    const [seccionDatosExperienciaLaboral, setSeccionDatosExperienciaLaboral] = useState({});

    const [modalResponseGuardarDisplayOff, setModalResponseGuardarDisplayOff] = useState("modal-display-off");
    const [modalImageUrl, setModalImageUrl] = useState("");
    const [modalResponseMessage, setModalResponseMessage] = useState("");
    const [modalSolicitarCambiosDisplayOff, setModalSolicitarCambiosDisplayOff] = useState("modal-display-off");
    const [idSeccionShowModalSolicitarCambios, setIdSeccionShowModalSolicitarCambios] = useState();

    const [detalleEmpleado, setDetalleEmpleado] = useState({});
    const [hideDetalleEmpleado, setHideDetalleEmpleado] = useState(true);

    const [showModalResponseGuardar, setShowModalResponseGuardar] = useState(false);
    const [showModalSolicitarCambios, setShowModalSolicitarCambios] = useState(false);
    const [reloadViewSeccionDatosPersonales, setReloadViewDatosPersonales] = useState(false);
    const [reloadViewSeccionDatosNacimiento, setReloadViewDatosNacimiento] = useState(false);
    const [reloadViewSeccionDatosEmpresa, setReloadViewDatosEmpresa] = useState(false);

    const [openFromPageName, setOpenFromPageName] = useState();
    const [puedoVerEstaFicha, setPuedoVerEstaFicha] = useState(false);
    const [puedoEditarSeccionFormacionAcademica, setPuedoEditarSeccionFormacionAcademica] = useState(false);
    const [puedoEditarSeccionExperienciaLaboral, setPuedoEditarSeccionExperienciaLaboral] = useState(false);

    // const [statusUpdateNacimiento, setStatusUpdateNacimiento] = useState({});

    const [datosNacimientoHasBeenUpdated, setDatosNacimientoHasBeenUpdated] = useState(true);
    const [datosResidenciaHasBeenUpdated, setDatosResidenciaHasBeenUpdated] = useState(true);

    const [hideModalInformativo, setHideModalInformativo] = useState(true);
    const [modalEnabled, setModalEnabled] = useState({ isEnable: false, component: React.Fragment, refreshPage: () => {}, data: {} });

    const [menuFichaDatos, setMenuFichaDatos] = useState([]);
    const [seccionesDatosPersonales, setSeccionesDatosPersonales] = useState([]);
    const [seccionesFormacionAcademica, setSeccionesFormacionAcademica] = useState([]);
    const [seccionesDatosFamiliares, setSeccionesDatosFamiliares] = useState([]);
    const [seccionesDatosExperienciaLaboral, setSeccionesDatosExperienciaLaboral] = useState([]);
    const [seccionesArchivos, setSeccionesArchivos] = useState([]);

    const [seccionTipoInstruccion, setSeccionTipoInstruccion] = useState({});
    const [seccionFormacionProfesional, setSeccionFormacionProfesional] = useState({});
    const [seccionEstudiosAdicionales, setSeccionEstudiosAdicionales] = useState({});
    const [seccionIdiomas, setSeccioIdiomas] = useState({});
    const [subSeccionesArchivos, setSubSeccionesArchivos] = useState({});

    const [executeGetRequest] = useGetRequest();
    const [executeManageGetRequest] = useManageGetRequest();

    const archivosSecciones = Object.entries(MENUS_FICHA_DATOS.archivos.secciones).reduce((acc, [key, value]) => {
        acc[value.id] = key;
        return acc;
    }, {})

    const reloadViewPageSeccion = (idSeccion) => {
        console.debug("idSeccionShowModalSolicitarCambios ", idSeccion);

        switch (idSeccion) {
            case MENUS_FICHA_DATOS.datosPersonales.secciones.DatosIdentificacion.id: {
                setReloadViewDatosPersonales(!reloadViewSeccionDatosPersonales);
                break;
            }
            case MENUS_FICHA_DATOS.datosPersonales.secciones.DatosDeNacimiento.id: {
                setReloadViewDatosNacimiento(!reloadViewSeccionDatosNacimiento);
                break;
            }
            case MENUS_FICHA_DATOS.datosEmpresa.secciones.datosEmpresa.id: {
                setReloadViewDatosEmpresa(!reloadViewSeccionDatosEmpresa);
                break;
            }
        }
    };

    const closeUpdateCorfirmationModal = () => {
        console.debug("closeUpdateCorfirmationModal");
        setModalImageUrl("");
        setModalResponseMessage("");
        setShowModalResponseGuardar(false);
    };

    const showUpdateConfirmationModal = (url, message) => {
        setModalImageUrl(url);
        setModalResponseMessage(message);
        setShowModalResponseGuardar(true);
    };


    useDidMountEffect(() => {
        if (showModalSolicitarCambios) {
            setModalSolicitarCambiosDisplayOff("");
        } else {
            setModalSolicitarCambiosDisplayOff("modal-display-off");
        }
    }, [showModalSolicitarCambios]);

    useDidMountEffect(() => {
        if (showModalResponseGuardar) {
            setModalResponseGuardarDisplayOff("");
        } else {
            setModalResponseGuardarDisplayOff("modal-display-off");
        }
    }, [showModalResponseGuardar]);


    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        if (empleadoId && empleadoId === state.user.id_empleado) {
            setHideDetalleEmpleado(true);
        } else {
            setHideDetalleEmpleado(false);
        }
    }, [empleadoId]);

    useDidMountEffect(() => {
        if (params && params.id) {
            setEmpleadoId(params.id);
        } else {
            setEmpleadoId(state.user.id_empleado);
        }
    }, [params.id]);

    useDidMountEffect(() => {
        if (params && params.from) {
            console.debug("params.from: ", params.from);
            setOpenFromPageName(params.from);
        }
    }, [params.from]);

    useDidMountEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, [datosNacimientoHasBeenUpdated, datosResidenciaHasBeenUpdated]);

    const checkIfPuedoVerEstaFichaFunction = async (idEmpleado) => {
        let response = await executeGetRequest(puedoVerEstaFichaApiCallAPI(idEmpleado));
        if (responseCode200(response)) {
            setPuedoVerEstaFicha(true);
            return true;
        } else {
            setPuedoVerEstaFicha(false);
            mostrarMensajeDeErrorConToast(response);
            return false;
        }
    };

    const checkIfPuedoEditarSeccionFormacionAcademica = async (idEmpleado) => {
        let response = await executeGetRequest(puedoEditarSeccionFormacionAcademicaCallAPI(idEmpleado));
        if (responseCode200(response)) {
            setPuedoEditarSeccionFormacionAcademica(response?.data?.Result === true);
        } else {
            setPuedoEditarSeccionFormacionAcademica(false);
        }
    }

    const checkIfPuedoEditarSeccionExperienciaLaboral = async (idEmpleado) => {
        let response = await executeGetRequest(puedoEditarSeccionExperienciaLaboralCallAPI(idEmpleado));
        if (responseCode200(response)) {
            setPuedoEditarSeccionExperienciaLaboral(response?.data?.Result === true);
        } else {
            setPuedoEditarSeccionExperienciaLaboral(false);
        }
    }

    // const getStatusUpdateNacimiento = async function (idEmpleadoo) {
    //     try {
    //         if (idEmpleadoo) {
    //             const response = await executeGetRequest(getDatosNacimientoAPI(idEmpleadoo));
    //             if (responseCode200(response)) {
    //                 setStatusUpdateNacimiento(response.data.validationData);
    //             } else {
    //                 mostrarMensajeDeErrorConToast(response);
    //             }
    //         } else {
    //             console.warn("id de empleado no válido: ", idEmpleadoo);
    //         }
    //     } catch (e) {
    //         console.error(e);
    //     }
    // };

    const manageMostrarModalInformativo = async function (empleadoIddd) {
        const response = await executeGetRequest(getSiMostrarModalInformativoAPI(empleadoIddd));

        if (responseCode200(response)) {
            setHideModalInformativo(!response.data.Result);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const init = async () => {
        let id;
        if (params && params.id) {
            setEmpleadoId(params.id);
            id = params.id;
        } else {
            setEmpleadoId(state.user.id_empleado);
            id = state.user.id_empleado;
        }

        await getFichaForms(id);


        let puedoVerFicha = (id) ? await checkIfPuedoVerEstaFichaFunction(id) : false;

        if (puedoVerFicha) {
            await obtenerDetalleDeEmpleado(id);

            await reloadSeccionesDatosPersonales(id);
            await reloadSeccionesFormacionAcademica(id);
            await reloadSeccionesDatosFamiliares(id);
            await reloadSeccionesExperienciaLaboral(id);
            await reloadSeccionesArchivos(id);
        }

        await checkIfPuedoEditarSeccionExperienciaLaboral(id);
        await checkIfPuedoEditarSeccionFormacionAcademica(id);
    };

    const reloadSeccionesArchivos = async (id) => {
        const api = getSeccionesByIdFormAPI(id, MENUS_FICHA_DATOS.archivos.id);
        await executeManageGetRequest(api, handleSuccessSeccionesArchivos);
    }

    const handleSuccessSeccionesArchivos = ({ data: secciones }) => {
        let idSecciones = secciones.map(o => o?.IdFichaSeccion);
        setSeccionesArchivos(idSecciones);

        let subSecciones = {};
        for (let seccion of secciones){
            const seccionFound = archivosSecciones[seccion.IdFichaSeccion];
            if (seccionFound){
                subSecciones[seccion.IdFichaSeccion] = seccion;
            }
        }
        setSubSeccionesArchivos(subSecciones);
    }

    const reloadSeccionesExperienciaLaboral = async (id) => {
        let response = await executeGetRequest(getSeccionesByIdFormAPI(id, MENUS_FICHA_DATOS.laboralData.id));
        if (responseCode200(response)) {
            let idsSecciones = response.data.map((item) => item?.IdFichaSeccion);
            setSeccionesDatosExperienciaLaboral(idsSecciones);

            let secciones = response.data;

            for (let seccion of secciones) {
                if (seccion.IdFichaSeccion === MENUS_FICHA_DATOS.laboralData.secciones.experienciaLaboral.id) {
                    setSeccionDatosExperienciaLaboral(seccion);
                }
            }

        }else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const reloadSeccionesFormacionAcademica = async (id) => {
        let response = await executeGetRequest(getSeccionesByIdFormAPI(id, MENUS_FICHA_DATOS.academicData.id));
        if (responseCode200(response)) {
            let idsSecciones = response.data.map((item) => item?.IdFichaSeccion);
            setSeccionesFormacionAcademica(idsSecciones);

            let secciones = response.data;

            for (let seccion of secciones) {
                if (seccion.IdFichaSeccion === MENUS_FICHA_DATOS.academicData.secciones.tipoInstruccion.id) {
                    setSeccionTipoInstruccion(seccion);
                }
                if (seccion.IdFichaSeccion === MENUS_FICHA_DATOS.academicData.secciones.formacionProfesional.id) {
                    setSeccionFormacionProfesional(seccion);
                }
                if (seccion.IdFichaSeccion === MENUS_FICHA_DATOS.academicData.secciones.estudiosAdicionales.id) {
                    setSeccionEstudiosAdicionales(seccion);
                }
                if (seccion.IdFichaSeccion === MENUS_FICHA_DATOS.academicData.secciones.idiomas.id) {
                    setSeccioIdiomas(seccion);
                }
            }

        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const reloadSeccionesDatosFamiliares = async (id) => {
        let response = await executeGetRequest(getSeccionesByIdFormAPI(id, MENUS_FICHA_DATOS.datosFamiliares.id));
        if (responseCode200(response)) {
            let idsSecciones = response.data.map((item) => item?.IdFichaSeccion);
            setSeccionesDatosFamiliares(idsSecciones);

            let secciones = response.data;

            for (let seccion of secciones) {
                if (seccion.IdFichaSeccion === MENUS_FICHA_DATOS.datosFamiliares.secciones.datosFamiliares.id) {
                    setSeccionDatosFamiliares(seccion);
                }
                if (seccion.IdFichaSeccion === MENUS_FICHA_DATOS.datosFamiliares.secciones.asignacionFamliar.id) {
                    setSeccionAsignacionFamiliar(seccion);
                }
            }
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const reloadSeccionesDatosPersonales = async (id) => {
        let response = await executeGetRequest(getSeccionesByIdFormAPI(id, MENUS_FICHA_DATOS.datosPersonales.id));
        if (responseCode200(response)) {
            let idsSecciones = response.data.map((item) => item?.IdFichaSeccion);
            setSeccionesDatosPersonales(idsSecciones);

            let secciones = response.data;

            for (let seccion of secciones) {
                if (seccion.IdFichaSeccion === MENUS_FICHA_DATOS.datosPersonales.secciones.DatosIdentificacion.id) {
                    setSeccionDatosPersonales(seccion);
                }
                if (seccion.IdFichaSeccion === MENUS_FICHA_DATOS.datosPersonales.secciones.DatosDeNacimiento.id) {
                    setSeccionDatosDeNacimiento(seccion);
                    // await getStatusUpdateNacimiento(id);
                }
                if (seccion.IdFichaSeccion === MENUS_FICHA_DATOS.datosPersonales.secciones.InformacionDeResidencia.id) {
                    setSeccionInformacionDeResidencia(seccion);
                }
                if (seccion.IdFichaSeccion === MENUS_FICHA_DATOS.datosPersonales.secciones.ContactoEmergencia.id) {
                    setSeccionContactoEmergencia(seccion);
                }
            }

            if (secciones && secciones.length > 0) {
                await manageMostrarModalInformativo(id);
            }

        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const getFichaForms = async (idEmpleadooo) => {
        let response = await executeGetRequest(getFichaFormsAPI(idEmpleadooo));
        if (responseCode200(response)) {
            let fichaFormsArray = response.data.map((item) => item?.Value);
            setMenuFichaDatos(fichaFormsArray);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const obtenerDetalleDeEmpleado = async (emppleadoId) => {
        try {
            const response = empleadoId && empleadoId === state.user.id_empleado ? await executeGetRequest(getDataEmpleadoAPI(emppleadoId)) : await executeGetRequest(getGestionFichaDatos(emppleadoId))
            if (responseCode200(response)) {
                setDetalleEmpleado(response.data);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const showSolicitarCambiosModal = async (sectionId) => {
        setIdSeccionShowModalSolicitarCambios(sectionId);
    };

    const toggleModalInformativo = async () => {
        setHideModalInformativo(!hideModalInformativo);
    };

    const tienePermiso = function (idsMenusAlosQueTienePermiso, idMenu) {
        if (isNullOrUndefinded(idsMenusAlosQueTienePermiso)) {
            return false;
        }

        return (idsMenusAlosQueTienePermiso.indexOf(idMenu) !== -1);
    };

    return (
        <>
            {puedoVerEstaFicha === true &&
            <>
                <div className="message-no-disponible-en-mobile">
                    <p>Mis Datos no está disponible en mobile.</p>
                </div>
                <div className="no-disponible-en-mobile">
                    <div className={`modal-response-ficha-background ${modalResponseGuardarDisplayOff}`}>
                        <div className="modal-response-ficha-content">
                            <img src={modalImageUrl}></img>
                            <p className="modal-response-ficha-content-message">{modalResponseMessage}</p>
                            <button className="modal-response-ficha-content-button" onClick={closeUpdateCorfirmationModal}> Volver a ficha de datos</button>
                        </div>
                    </div>
                    <div className={`modal-response-ficha-background ${modalSolicitarCambiosDisplayOff}`}>
                        <ModalSolicitarCambios setShowModalSolicitarCambios={setShowModalSolicitarCambios} showUpdateConfirmationModal={showUpdateConfirmationModal} idSeccionShowModalSolicitarCambios={idSeccionShowModalSolicitarCambios}
                                               setIdSeccionShowModalSolicitarCambios={setIdSeccionShowModalSolicitarCambios} reloadViewPageSeccion={reloadViewPageSeccion}/>
                    </div>

                    <div className="modal-contactanos modal-response-ficha-background" hidden={hideModalInformativo}>
                        {hideModalInformativo === false &&
                        <ModalInformativo showModal={toggleModalInformativo}/>
                        }
                    </div>

                    <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled}/>

                    <div>
                        {hideDetalleEmpleado === true &&  <PageHeader title={"Mis Datos"}/> }
                        {hideDetalleEmpleado === false &&  <PageHeader title={"Datos de colaborador"}/>}

                        <FichaDetalleEmpleado hideDetalleEmpleado={hideDetalleEmpleado} detalleEmpleado={detalleEmpleado} openFromPageName={openFromPageName}/>

                        <div className='container-fluid body-content' style={{'borderTop': '0.5px solid #eae5e5'}}>
                            <ul
                                className='nav nav-tabs nav-groupy'
                                id='tabFicha'
                                role='tablist'
                                style={{
                                    display: 'flex !important',
                                    justifyContent: 'flex-start',
                                    flexWrap: 'inherit',
                                }}>
                                {tienePermiso(menuFichaDatos, MENUS_FICHA_DATOS.datosPersonales.id) &&
                                <li className='nav-item nav-link-groupy' role='presentation'>
                                    <a
                                        className='nav-link active font-gothan-medium'
                                        id='tabDatosPersonales'
                                        data-toggle='tab'
                                        href='#divDatosPersonales'
                                        role='tab'
                                        aria-controls='home'
                                        aria-selected='true'>
                                        Datos Personales
                                    </a>
                                </li>
                                }
                                {tienePermiso(menuFichaDatos, MENUS_FICHA_DATOS.datosFamiliares.id) &&
                                <li className='nav-item nav-link-groupy' role='presentation'>
                                    <a
                                        className='nav-link nav-link-a font-gothan-medium'
                                        id='tabDatosFamiliares'
                                        data-toggle='tab'
                                        href='#divDatosFamiliares'
                                        role='tab'
                                        aria-controls='contact'
                                        aria-selected='false'>
                                        Datos Familiares
                                    </a>
                                </li>
                                }
                                {tienePermiso(menuFichaDatos, MENUS_FICHA_DATOS.academicData.id) &&
                                <li className='nav-item nav-link-groupy' role='presentation'>
                                    <a
                                        className='nav-link nav-link-a font-gothan-medium'
                                        id='tabDatosFormacionAcademica'
                                        data-toggle='tab'
                                        href='#divFormacionAcademica'
                                        role='tab'
                                        aria-controls='academic'
                                        aria-selected='false'>
                                        Formación Académica
                                    </a>
                                </li>
                                }

                                {tienePermiso(menuFichaDatos, MENUS_FICHA_DATOS.laboralData.id) &&
                                <li className='nav-item nav-link-groupy' role='presentation'>
                                    <a
                                        className='nav-link nav-link-a font-gothan-medium'
                                        id='tabDatosExperienciaLaboral'
                                        data-toggle='tab'
                                        href='#divExperienciaLaboral'
                                        role='tab'
                                        aria-controls='experience'
                                        aria-selected='false'>
                                        Experiencia Laboral
                                    </a>
                                </li>}

                                {tienePermiso(menuFichaDatos, MENUS_FICHA_DATOS.datosEmpresa.id) &&
                                <li className='nav-item nav-link-groupy' role='presentation'>
                                    <a
                                        className='nav-link nav-link-a font-gothan-medium'
                                        id='tabDatosInnova'
                                        data-toggle='tab'
                                        href='#divDatosInnova'
                                        role='tab'
                                        aria-controls='profile'
                                        aria-selected='false'>
                                        Datos Empresa
                                    </a>
                                </li>
                                }
                                {tienePermiso(menuFichaDatos, MENUS_FICHA_DATOS.archivos.id) &&
                                <li className='nav-item nav-link-groupy' role='presentation'>
                                    <a
                                        className='nav-link nav-link-a font-gothan-medium'
                                        id='tabCierreDeAnio'
                                        data-toggle='tab'
                                        href='#divCierreDeAnio'
                                        role='tab'
                                        aria-controls='cierreanio'
                                        aria-selected='false'>
                                        Archivos
                                    </a>
                                </li>
                                }
                            </ul>

                            <div className='tab-content bg-white'>
                                {tienePermiso(menuFichaDatos, MENUS_FICHA_DATOS.datosPersonales.id) &&
                                <div
                                    className='tab-pane fade show active'
                                    id='divDatosPersonales'
                                    role='tabpanel'
                                    aria-labelledby='home-tab'>
                                    <div id='accordion-datospersonales'>
                                        {tienePermiso(seccionesDatosPersonales, MENUS_FICHA_DATOS.datosPersonales.secciones.DatosIdentificacion.id) &&
                                        <div className='card'>
                                            <div id='headingOne-datospersonales' className={seccionDatosPersonales?.Completado === false ? 'card-header ficha-falta-completar' : 'card-header' } title={seccionDatosPersonales?.Completado === false ? mensajeSeccionFaltaCompletar : mensajeSeccionCompletada }>
                                                <h5 className='mb-0'>
                                                    <button
                                                        className='btn btn-link font-gothan-medium ficha-titulo-seccion-color'
                                                        data-toggle='collapse'
                                                        data-target='#colDatosPersonales'
                                                        aria-expanded='true'
                                                        aria-controls='colDatosPersonales'>
                                                        {seccionDatosPersonales && seccionDatosPersonales.NombreSeccion}
                                                    </button>
                                                </h5>
                                            </div>
                                            <div
                                                id='colDatosPersonales'
                                                className='collapse show'
                                                aria-labelledby='headingOne-datospersonales'
                                                data-parent='#accordion-datospersonales'>

                                                {empleadoId &&
                                                <DatosPersonales empleadoId={empleadoId} showUpdateConfirmationModal={showUpdateConfirmationModal} showSolicitarCambiosModal={showSolicitarCambiosModal} reloadView={reloadViewSeccionDatosPersonales} reloadSeccionesDatosPersonales={reloadSeccionesDatosPersonales}/>
                                                }
                                            </div>
                                        </div>
                                        }

                                        {tienePermiso(seccionesDatosPersonales, MENUS_FICHA_DATOS.datosPersonales.secciones.DatosDeNacimiento.id) &&
                                        <div className='card'>
                                            <div id='headingTwo-datospersonales' className={seccionDatosDeNacimiento?.Completado === false ? 'card-header ficha-secciones-title ficha-falta-completar' : 'card-header ficha-secciones-title' } title={seccionDatosDeNacimiento?.Completado === false ? mensajeSeccionFaltaCompletar : mensajeSeccionCompletada }>
                                                <h5 className='mb-0'>
                                                    <button
                                                        className='btn btn-link collapsed font-gothan-medium ficha-titulo-seccion-color'
                                                        data-toggle='collapse'
                                                        data-target='#colDatosNacimiento'
                                                        aria-expanded='false'
                                                        aria-controls='colDatosNacimiento'>
                                                        {seccionDatosDeNacimiento && seccionDatosDeNacimiento.NombreSeccion}
                                                    </button>
                                                </h5>
                                                {/* <FichaDatosSeccionTitleMessage statusUpdate={statusUpdateNacimiento}/> */}
                                            </div>
                                            <div
                                                id='colDatosNacimiento'
                                                className='collapse'
                                                aria-labelledby='headingTwo-datospersonales'
                                                data-parent='#accordion-datospersonales'>

                                                {empleadoId &&
                                                <DatosDeNacimiento empleadoId={empleadoId} showSolicitarCambiosModal={showSolicitarCambiosModal} showUpdateConfirmationModal={showUpdateConfirmationModal} reloadView={reloadViewSeccionDatosNacimiento}
                                                                   datosNacimientoHasBeenUpdated={datosNacimientoHasBeenUpdated} setDatosNacimientoHasBeenUpdated={setDatosNacimientoHasBeenUpdated} reloadSeccionesDatosPersonales={reloadSeccionesDatosPersonales}/>
                                                }
                                            </div>
                                        </div>
                                        }
                                        {tienePermiso(seccionesDatosPersonales, MENUS_FICHA_DATOS.datosPersonales.secciones.InformacionDeResidencia.id) &&
                                        <div className='card'>
                                            <div id='headingThree-datospersonales' className={seccionInformacionDeResidencia?.Completado === false ? 'card-header ficha-secciones-title ficha-falta-completar' : 'card-header ficha-secciones-title' } title={seccionInformacionDeResidencia?.Completado === false ? mensajeSeccionFaltaCompletar : mensajeSeccionCompletada }>
                                                <h5 className='mb-0'>
                                                    <button
                                                        className='btn btn-link collapsed font-gothan-medium ficha-titulo-seccion-color'
                                                        data-toggle='collapse'
                                                        data-target='#colInformacionResidencia'
                                                        aria-expanded='false'
                                                        aria-controls='colInformacionResidencia'>
                                                        {seccionInformacionDeResidencia && seccionInformacionDeResidencia.NombreSeccion}
                                                    </button>
                                                </h5>
                                            </div>
                                            <div
                                                id='colInformacionResidencia'
                                                className='collapse'
                                                aria-labelledby='headingThree-datospersonales'
                                                data-parent='#accordion-datospersonales'>

                                                {empleadoId &&
                                                <DatosDeResidencia empleadoId={empleadoId} showUpdateConfirmationModal={showUpdateConfirmationModal} datosResidenciaHasBeenUpdated={datosResidenciaHasBeenUpdated}
                                                                   setDatosResidenciaHasBeenUpdated={setDatosResidenciaHasBeenUpdated} reloadSeccionesDatosPersonales={reloadSeccionesDatosPersonales}/>
                                                }
                                            </div>
                                        </div>
                                        }

                                        {tienePermiso(seccionesDatosPersonales, MENUS_FICHA_DATOS.datosPersonales.secciones.ContactoEmergencia.id) &&
                                        <div className='card'>
                                            <div id='headingFour-datospersonales' className={seccionContactoEmergencia?.Completado === false ? 'card-header  ficha-falta-completar' : 'card-header ' } title={seccionContactoEmergencia?.Completado === false ? mensajeSeccionFaltaCompletar : mensajeSeccionCompletada }>
                                                <h5 className='mb-0'>
                                                    <button
                                                        className='btn btn-link collapsed font-gothan-medium ficha-titulo-seccion-color'
                                                        data-toggle='collapse'
                                                        data-target='#colInformacionMedica'
                                                        aria-expanded='false'
                                                        aria-controls='colInformacionMedica'>
                                                        {seccionContactoEmergencia && seccionContactoEmergencia.NombreSeccion}
                                                    </button>

                                                    {seccionContactoEmergencia.Mensaje &&
                                                    <>
                                                        <p data-tip data-for="informacion-medica-title" style={{display: 'inline'}}>
                                                            <FontAwesomeIcon icon='info-circle' className="hand-pointer ficha-residencia-ver-info-icon"/>
                                                        </p>
                                                        <ReactTooltip id="informacion-medica-title" place="right" effect="solid" className="tooltip-style">
                                                            {seccionContactoEmergencia && <p>{seccionContactoEmergencia.Mensaje}</p>}
                                                        </ReactTooltip>
                                                    </>
                                                    }
                                                </h5>
                                            </div>
                                            <div
                                                id='colInformacionMedica'
                                                className='collapse'
                                                aria-labelledby='headingFour-datospersonales'
                                                data-parent='#accordion-datospersonales'>
                                                {empleadoId &&
                                                <InformacionContactoEmergencia empleadoId={empleadoId} showModal={showUpdateConfirmationModal} reloadSeccionesDatosPersonales={reloadSeccionesDatosPersonales}/>
                                                }

                                            </div>
                                        </div>
                                        }

                                    </div>
                                </div>
                                }
                                {tienePermiso(menuFichaDatos, MENUS_FICHA_DATOS.datosFamiliares.id) &&
                                <div
                                    className='tab-pane fade'
                                    id='divDatosFamiliares'
                                    role='tabpanel'
                                    aria-labelledby='contact-tab'>
                                    <div id='accordion-datosFamiliares'>
                                        {tienePermiso(seccionesDatosFamiliares, MENUS_FICHA_DATOS.datosFamiliares.secciones.datosFamiliares.id) &&
                                            <div className='card'>
                                                <div id='headingFamilia' className={seccionDatosFamiliares?.Completado === false ? 'card-header ficha-falta-completar' : 'card-header' } title={seccionDatosFamiliares?.Completado === false ? mensajeSeccionFaltaCompletar : mensajeSeccionCompletada }>
                                                    <h5 className='mb-0'>
                                                        <button
                                                            className='btn btn-link font-gothan-medium ficha-titulo-seccion-color'
                                                            data-toggle='collapse'
                                                            data-target='#colDatosFamiliares'
                                                            aria-expanded='true'
                                                            aria-controls='colDatosFamiliares'>
                                                            Mi Familia
                                                        </button>
                                                    </h5>
                                                </div>
                                                <div
                                                    id='colDatosFamiliares'
                                                    className='collapse show'
                                                    aria-labelledby='headingFamilia'
                                                    data-parent='#accordion-datosFamiliares'>

                                                    {empleadoId &&
                                                    <DatosFamiliares empleadoId={empleadoId} showModal={showUpdateConfirmationModal} showSolicitarCambiosModal={showSolicitarCambiosModal} reloadSeccionesDatosFamiliares={reloadSeccionesDatosFamiliares}/>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {tienePermiso(seccionesDatosFamiliares, MENUS_FICHA_DATOS.datosFamiliares.secciones.asignacionFamliar.id) && 
                                            <DatosAsignacionFamiliar empleadoId={empleadoId} seccionAsignacionFamiliar={seccionAsignacionFamiliar} setModalEnabled={setModalEnabled} seccionDatosFamiliares={seccionDatosFamiliares}/>
                                        }
                                    </div>
                                </div>
                                }
                                {tienePermiso(menuFichaDatos, MENUS_FICHA_DATOS.academicData.id) &&
                                <div
                                    className='tab-pane fade'
                                    id='divFormacionAcademica'
                                    role='tabpanel'
                                    aria-labelledby='academic-tab'>
                                    <div id='accordion-FormacionAcademica'>
                                        {tienePermiso(seccionesFormacionAcademica, MENUS_FICHA_DATOS.academicData.secciones.tipoInstruccion.id) &&
                                        <div className='card'>
                                            <div id='headingOneFormacionAcademica'  className={seccionTipoInstruccion?.Completado === false ? 'card-header ficha-falta-completar' : 'card-header' } title={seccionTipoInstruccion?.Completado === false ? mensajeSeccionFaltaCompletar : mensajeSeccionCompletada }>
                                                <h5 className='mb-0'>
                                                    <button
                                                        className='btn btn-link font-gothan-medium ficha-titulo-seccion-color'
                                                        data-toggle='collapse'
                                                        data-target='#colTipoInstruccion'
                                                        aria-expanded='true'
                                                        aria-controls='colTipoInstruccion'>
                                                        {seccionTipoInstruccion && seccionTipoInstruccion.NombreSeccion}
                                                    </button>
                                                </h5>
                                            </div>
                                            <div
                                                id='colTipoInstruccion'
                                                className='collapse show'
                                                aria-labelledby='headingOneFormacionAcademica'
                                                data-parent='#accordion-FormacionAcademica'>

                                                {empleadoId &&
                                                <TipoInstruccion empleadoId={empleadoId} detalleEmpleado={detalleEmpleado} puedoEditar={puedoEditarSeccionFormacionAcademica}/>
                                                }
                                            </div>
                                        </div>
                                        }
                                        {tienePermiso(seccionesFormacionAcademica, MENUS_FICHA_DATOS.academicData.secciones.formacionProfesional.id) &&
                                        <div className='card'>
                                            <div id='headingTwoFormacionAcademica' className={seccionFormacionProfesional?.Completado === false ? 'card-header ficha-falta-completar' : 'card-header ficha-secciones-title' } title={seccionFormacionProfesional?.Completado === false ? mensajeSeccionFaltaCompletar : mensajeSeccionCompletada }>
                                                <h5 className='mb-0'>
                                                    <button
                                                        className='btn btn-link collapsed font-gothan-medium ficha-titulo-seccion-color'
                                                        data-toggle='collapse'
                                                        data-target='#colFormacionProfesional'
                                                        aria-expanded='false'
                                                        aria-controls='colFormacionProfesional'>
                                                        {seccionFormacionProfesional && seccionFormacionProfesional.NombreSeccion}
                                                    </button>
                                                </h5>
                                            </div>
                                            <div
                                                id='colFormacionProfesional'
                                                className='collapse'
                                                aria-labelledby='headingTwoFormacionAcademica'
                                                data-parent='#accordion-FormacionAcademica'>

                                                {empleadoId &&
                                                <FormacionProfesional empleadoId={empleadoId} puedoEditar={puedoEditarSeccionFormacionAcademica}/>
                                                }
                                            </div>
                                        </div>
                                        }
                                        {tienePermiso(seccionesFormacionAcademica, MENUS_FICHA_DATOS.academicData.secciones.estudiosAdicionales.id) &&
                                        <div className='card'>
                                            <div id='headingThreeFormacionAcademica' className={seccionEstudiosAdicionales?.Completado === false ? 'card-header ficha-falta-completar' : 'card-header ficha-secciones-title' } title={seccionEstudiosAdicionales?.Completado === false ? mensajeSeccionFaltaCompletar : mensajeSeccionCompletada }>
                                                <h5 className='mb-0'>
                                                    <button
                                                        className='btn btn-link collapsed font-gothan-medium ficha-titulo-seccion-color'
                                                        data-toggle='collapse'
                                                        data-target='#colEstudiosAdicionales'
                                                        aria-expanded='false'
                                                        aria-controls='colEstudiosAdicionales'>
                                                        {seccionEstudiosAdicionales && seccionEstudiosAdicionales.NombreSeccion}
                                                    </button>
                                                </h5>
                                            </div>
                                            <div
                                                id='colEstudiosAdicionales'
                                                className='collapse'
                                                aria-labelledby='headingThreeFormacionAcademica'
                                                data-parent='#accordion-FormacionAcademica'>

                                                {empleadoId &&
                                                <EstudiosAdicionales
                                                    empleadoId={empleadoId} puedoEditar={puedoEditarSeccionFormacionAcademica}/>
                                                }
                                            </div>
                                        </div>
                                        }
                                        {tienePermiso(seccionesFormacionAcademica, MENUS_FICHA_DATOS.academicData.secciones.idiomas.id) &&
                                        <div className='card'>
                                            <div id='headingFourFormacionAcademica' className={seccionIdiomas?.Completado === false ? 'card-header ficha-falta-completar' : 'card-header ficha-secciones-title' } title={seccionIdiomas?.Completado === false ? mensajeSeccionFaltaCompletar : mensajeSeccionCompletada }>
                                                <h5 className='mb-0'>
                                                    <button
                                                        className='btn btn-link collapsed font-gothan-medium ficha-titulo-seccion-color'
                                                        data-toggle='collapse'
                                                        data-target='#colIdiomas'
                                                        aria-expanded='false'
                                                        aria-controls='colIdiomas'>
                                                        {seccionIdiomas && seccionIdiomas.NombreSeccion}
                                                    </button>
                                                </h5>
                                            </div>
                                            <div
                                                id='colIdiomas'
                                                className='collapse'
                                                aria-labelledby='headingFourFormacionAcademica'
                                                data-parent='#accordion-FormacionAcademica'>

                                                {empleadoId &&
                                                <ListarEmpleadoIdiomas
                                                    empleadoId={empleadoId}
                                                    puedoEditar={puedoEditarSeccionFormacionAcademica}
                                                />
                                                }
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                                }
                                {tienePermiso(menuFichaDatos, MENUS_FICHA_DATOS.laboralData.id) &&
                                <div
                                    className='tab-pane fade'
                                    id='divExperienciaLaboral'
                                    role='tabpanel'
                                    aria-labelledby='experience-tab'>
                                    <div id='accordion-experienciaLaboral'>
                                        {tienePermiso(seccionesDatosExperienciaLaboral, MENUS_FICHA_DATOS.laboralData.secciones.experienciaLaboral.id) &&
                                            <div className='card'>
                                                <div id='headingFourExperienciaLaboral' className={seccionDatosExperienciaLaboral?.Completado === false ? 'card-header ficha-falta-completar' : 'card-header ficha-secciones-title' } title={seccionDatosExperienciaLaboral?.Completado === false ? mensajeSeccionFaltaCompletar : mensajeSeccionCompletada }>
                                                    <h5 className='mb-0'>
                                                        <button
                                                                className='btn btn-link collapsed font-gothan-medium ficha-titulo-seccion-color'>
                                                                Mi Experiencia
                                                        </button>
                                                    </h5>
                                                </div>
                                                <div>
                                                    {empleadoId &&
                                                    <DatosExperienciaLaboral empleadoId={empleadoId} puedoEditar={puedoEditarSeccionExperienciaLaboral}/>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                 </div>
                                }

                                {tienePermiso(menuFichaDatos, MENUS_FICHA_DATOS.datosEmpresa.id) &&
                                <div
                                    className='tab-pane fade'
                                    id='divDatosInnova'
                                    role='tabpanel'
                                    aria-labelledby='profile-tab'>
                                    <div id='accordion-datosEmpresa'>
                                        <div className='card'>
                                            <div>
                                                {empleadoId &&
                                                <DatosEmpresa empleadoId={empleadoId} showUpdateConfirmationModal={showUpdateConfirmationModal} showSolicitarCambiosModal={showSolicitarCambiosModal} reloadView={reloadViewSeccionDatosEmpresa}/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }

                                {tienePermiso(menuFichaDatos, MENUS_FICHA_DATOS.archivos.id) &&
                                <div
                                    className='tab-pane fade'
                                    id='divCierreDeAnio'
                                    role='tabpanel'
                                    aria-labelledby='cierreanio-tab'>
                                    <div id='accordion'>
                                        <div className='card'>
                                            <div>
                                                {empleadoId &&
                                                    <SeccionCierreDeAnio empleadoId={empleadoId} seccionesArchivos={seccionesArchivos} subSeccionesArchivos={subSeccionesArchivos} tienePermiso={tienePermiso}/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
            {puedoVerEstaFicha === false &&
            <p></p>
            }
        </>
    );
};

export default FichaDatos;
