import React, { useEffect, useState } from 'react'
import { DM_CONDICION, ESTADOS, MATERNIDAD_CONDICION } from '../../../../../constants/hardCodedConstants'
import Gestante from '../../components/Gestante'
import Lactancia from '../../components/Lactancia'
import { DatosMaternidadActions } from '../actions/Actions';
import SpinnerLoading from '../../../../../components/pageComponents/SpinnerLoading';
import { Div, MESES_GESTACION } from '../../components/Components';
import InputUploadFile from '../../../../../components/modalComponents/InputUploadFile';
import { useSubmitForm } from '../../../../../hooks/useSubmitForm/useSubmitForm';
import { usePropsInputs } from '../../../../../hooks/useProps/usePropsInput';
import { usePropsSelect } from '../../../../../hooks/useProps/usePropsSelect';
import { initialAllOptionPromises, jsonEstaVacio } from '../../../../../helper/utils';
import { editarCondicionAPI, getInstitucionesDescansoMedicoAPI, getMotivosDescansoMedicoAPI, getTipoDeCondicionAPI } from '../../../../../consumers/backendApisUrls';
import InputText from '../../../../../components/modalComponents/InputText';
import DescansoMedico from '../../components/DescansoMedico';
import { useGetOptionsSelect } from '../../../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import InputTextArea from '../../../../../components/modalComponents/InputTextArea';

const KEYS = {
  idCondicion: "IdCondicion",
  idEmpleado: "IdEmpleado",
  idTipoDeCondicion: "IdTipoDeCondicion",
  meses: "MesesGestacion",
  fechaNacimiento: "FechaNacimiento",
  fechaProbable: "FechaProbableDeParto",
  fechaUltMenstruacion: "FechaUltimaMenstruacion",
  nombre: "NombreBebe",
  file: "SustentoFile",
  preExistencia: "PreExistencia",
  horarioLactanciaMaternidad: "HorarioLactanciaMaternidad",
  cartaConvenioHorarioLactancia: "CartaConvenioHorarioLactanciaArchivo",
  docPreExistencia: "TieneDocPreExistencia",
  plantillaPreExistencia: "PlantillaPreExistenciaFileViewModel",
  docPreExistenciaFirmado: "DocPreExistenciaFirmadoFileViewModel",
  observacion: "Observacion",
  creadaDesdeGestacion: "CreadaDesdeGestacion",
  fechaInicioCondicion: "FechaInicioCondicion",
  fechaInicioCondicionText: "FechaInicioCondicionText",
  fechaFinCondicion: "FechaFinCondicion",
  fechaFinCondicionText: "FechaFinCondicionText",
  diagnostico: "Diagnostico",
  idMotivoDescansoMedico: "IdMotivoDescansoMedico",
  motivoDescansoMedico: "MotivoDescansoMedico",
  idInstitucionDescansoMedico: "IdInstitucionDescansoMedico",
  institucionDescansoMedico: "InstitucionDescansoMedico",
  cantidadDias: "CantidadDias",
  documentos: "Documentos",
  idEstadoAprobacion: "IdEstadoAprobacion",
  dMsAprobadosAcumuladosEsteAnioMayor20Dias: "DMsAprobadosAcumuladosEsteAnioMayor20Dias",
  dmsAprobadosAcumuladosEsteAnio: "DMsAprobadosAcumuladosEsteAnio",
  plantillaCartaCompromisoDM: "PlantillaCartaCompromisoDM",
  cartaCompromisoDMFirmada: "CartaCompromisoDMFirmada",
  tieneDocDescansoMedicoMayor20Dias: "TieneDocDescansoMedicoMayor20Dias",
  documentoCanjeDMEssaludCittFirmado: "DocumentoCanjeDMEssaludCittFirmadoFileViewModel",
  tieneSubsidio: "TieneSubsidio",
  registradoEnOfisis: "RegistradoEnOfisis",
  agregarCartaCompromiso: "AgregarCartaCompromiso",
  tieneSubsidioContinuado: "TieneSubsidioContinuado",
  comentario: "Comentario",
  
};

const KEYS_DROPDOWN = ["IdTipoDeCondicion", "IdMotivoDescansoMedico", "IdInstitucionDescansoMedico"];
const SECTION_NAME = "DatosMaternidadDetalle";

const DatosCondicion = ({ idCondicion: IdCondicion, idTipoDeCondicion, isEditable = false, toggleMainModal, refreshMainPage, isAdmin, row, idEmpleado }) => {

  const [formValues, setFormValues] = useState({});
  const [allDropdowns, setAllDropdowns] = useState([{ key: KEYS.meses, value: MESES_GESTACION }]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [selectProps] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);
  const [submitForm] = useSubmitForm();
  const [getOption] = useGetOptionsSelect();

  const init = async () => {
    setIsLoading(true);
    const { RegistroCondicionSustentoArchivo: SustentoFile, ...rest } = row;
    setFormValues({ SustentoFile, ...rest});
    getDropdowns();
    setIsLoading(false);
  }

  const getDropdowns = async () => {
    setIsLoading(true);
    const dropdownsPromises = () => [
      getOption(getTipoDeCondicionAPI(idEmpleado)),
      getOption(getMotivosDescansoMedicoAPI()),
      getOption(getInstitucionesDescansoMedicoAPI())
    ]
    await initialAllOptionPromises( dropdownsPromises, KEYS_DROPDOWN, handleDropdowns );
    setIsLoading(false);
  }

  const handleDropdowns = (data) => {
    const updatedData = [...data, {key: KEYS.meses, value: MESES_GESTACION}];
    setAllDropdowns(updatedData);
  }

  const handleEdit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = editarCondicionAPI();
    const payload = getPayload();
    try {
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successEditCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const getPayload = () => {
    let { SustentoFile, ...rest } = formValues
    let selectedStates = {};
    
    if (formValues[KEYS.idTipoDeCondicion] === MATERNIDAD_CONDICION.GESTANTE) {
      const { FechaProbableDeParto, MesesGestacion, FechaUltimaMenstruacion, DocPreExistenciaFirmadoFileViewModel } = rest;
      selectedStates = { FechaProbableDeParto, MesesGestacion, FechaUltimaMenstruacion, DocPreExistenciaFirmadoFileViewModel };
    }
    if (formValues[KEYS.idTipoDeCondicion] === MATERNIDAD_CONDICION.LACTANCIA) {
      const { FechaNacimiento, NombreBebe } = rest;
      selectedStates = { FechaNacimiento, NombreBebe };
    }
    if (formValues[KEYS.idTipoDeCondicion] === DM_CONDICION.DESCANSO_MEDICO){
      const {
        FechaInicioCondicion,
        FechaFinCondicion,
        Diagnostico,
        Comentario,
        InstitucionEmisora,
        IdMotivoDescansoMedico,
        IdInstitucionDescansoMedico,
        Documentos,
        TieneSubsidio,
        DMsAprobadosAcumuladosEsteAnioMayor20Dias,
        PlantillaCartaCompromisoDM,
        CartaCompromisoDMFirmada,
      } = rest;
      selectedStates = {
        FechaInicioCondicion,
        FechaFinCondicion,
        Diagnostico,
        Comentario,
        InstitucionEmisora,
        IdMotivoDescansoMedico,
        IdInstitucionDescansoMedico,
        Documentos,
        TieneSubsidio,
        DMsAprobadosAcumuladosEsteAnioMayor20Dias,
        PlantillaCartaCompromisoDM,
        CartaCompromisoDMFirmada,
      };
    }
    if (jsonEstaVacio(SustentoFile)) SustentoFile = null;
    return {
      IdCondicion,
      SustentoFile,
      ...selectedStates
    }
  }

  const successEditCallback = async () => {
    await refreshMainPage();
    toggleMainModal();
  }

  useEffect(() => {
    init();
  }, [])

  if (isLoading) return <SpinnerLoading />
    
  const gestantePreExistencia = idTipoDeCondicion === MATERNIDAD_CONDICION.GESTANTE && (
    <>
      <Div>
        <InputText label="Pre-Existencia" isDisabled {...commonProps(KEYS.preExistencia)} />
      </Div>
      {formValues.PuedeDescargarFormatoPreExistencia && (
        <>
          <Div>
            <InputText label="Doc. Pre-Existencia" isDisabled {...commonProps(KEYS.docPreExistencia)} />
          </Div>
          <Div>
            <InputUploadFile
              label="Descargar Formato"
              name={KEYS.plantillaPreExistencia}
              uploadFile={formValues[KEYS.plantillaPreExistencia]}
              sectionName={SECTION_NAME}
              isDisabled={true}
            />
          </Div>
          <Div>
            <InputUploadFile
              label="Documento Firmado"
              name={KEYS.docPreExistenciaFirmado}
              uploadFile={formValues[KEYS.docPreExistenciaFirmado]}
              setUploadFile={(res) =>
                setFormValues({
                  ...formValues,
                  [KEYS.docPreExistenciaFirmado]: res,
                })
              }
              sectionName={SECTION_NAME}
              isDisabled={!isEditable}
            />
          </Div>
        </>
      )}
    </>
  );

  const dmPreExistencia = ((formValues[KEYS.idEstadoAprobacion] === ESTADOS.OBSERVADO &&
    formValues[KEYS.dMsAprobadosAcumuladosEsteAnioMayor20Dias]) ||
    (formValues[KEYS.tieneDocDescansoMedicoMayor20Dias] && isAdmin)) && (
    <>
      <Div col={12} additionalClass="pb-2">
        <span className="ficha-sub-title-color">Detalle documento observado</span>
        <hr className="divider" />
      </Div>
      <Div>
        <InputText
          label="DMs Acumulados Mayor a 20"
          toolTipText="Indica si el trabajador ha acumulado más de 20 días de descansos médicos particulares aprobados durante el año"
          name={KEYS.dMsAprobadosAcumuladosEsteAnioMayor20Dias}
          isDisabled
          value={formValues[KEYS.dMsAprobadosAcumuladosEsteAnioMayor20Dias] ? "Sí" : "No"}
        />
      </Div>
      {formValues[KEYS.tieneDocDescansoMedicoMayor20Dias] && (
        <>
          <Div>
            <InputUploadFile
              label="Plantilla Carta Compromiso DM"
              name={KEYS.plantillaCartaCompromisoDM}
              uploadFile={formValues[KEYS.plantillaCartaCompromisoDM]}
              sectionName={SECTION_NAME}
              isDisabled
            />
          </Div>
          <Div>
            <InputUploadFile
              label="Carta Compromiso DM Firmada"
              name={KEYS.cartaCompromisoDMFirmada}
              uploadFile={formValues[KEYS.cartaCompromisoDMFirmada]}
              setUploadFile={(res) =>
                setFormValues({
                  ...formValues,
                  [KEYS.cartaCompromisoDMFirmada]: res,
                })
              }
              sectionName={SECTION_NAME}
              isDisabled={!isEditable}
            />
          </Div>
        </>
      )}
    </>
  );

  const lactanteCartaConvenio = idTipoDeCondicion === MATERNIDAD_CONDICION.LACTANCIA && (
    <Div>
      <InputUploadFile
        label="Carta convenio"
        name={KEYS.cartaConvenioHorarioLactancia}
        uploadFile={formValues[KEYS.cartaConvenioHorarioLactancia]}
        sectionName={SECTION_NAME}
        isDisabled={true}
        NoFileMessage={formValues[KEYS.creadaDesdeGestacion] ? "PENDIENTE" : "NO APLICA"}
      />
    </Div>
  );

  const saveButton = (
    <div className="col-md-12 text-right">
      {isEditable && (
        <button
          type="submit"
          className="btn btn-primary generic-button-ficha-modal"
          disabled={!isEditable || isSubmitting || isUploading}>
          Guardar
        </button>
      )}
    </div>
  );

  function condicionGestante() {
    return (
      idTipoDeCondicion === MATERNIDAD_CONDICION.GESTANTE && (
        <Gestante commonProps={commonProps} selectProps={selectProps} keys={KEYS} isDisabled={!isEditable} />
      )
    );
  }

  function condicionLactante() {
    return (
      idTipoDeCondicion === MATERNIDAD_CONDICION.LACTANCIA && (
        <Lactancia
          commonProps={commonProps}
          fechaNacimiento={formValues[KEYS.fechaNacimiento]}
          keys={KEYS}
          isDisabled={!isEditable}
        />
      )
    );
  }

  function condicionDescansoMedico() {
    return (
      idTipoDeCondicion === DM_CONDICION.DESCANSO_MEDICO && (
        <DescansoMedico
          selectProps={selectProps}
          commonProps={commonProps}
          fechaNacimiento={formValues[KEYS.fechaNacimiento]}
          formValues={formValues}
          setFormValues={setFormValues}
          setIsUploading={setIsUploading}
          sectionName={SECTION_NAME}
          KEYS={KEYS}
          isDisabled={!isEditable}
          dmsAprobadosAcumuladosEsteAnio={formValues[KEYS.dmsAprobadosAcumuladosEsteAnio]}
          isAdmin={isAdmin}
        />
      )
    );
  }

  function condicionMaternidad(){
    return (
      Object.values(MATERNIDAD_CONDICION).includes(idTipoDeCondicion) && (
        <Div>
          <InputUploadFile
            label="Sustento"
            name={KEYS.file}
            uploadFile={formValues[KEYS.file]}
            setUploadFile={(res) => setFormValues({ ...formValues, [KEYS.file]: res })}
            sectionName={SECTION_NAME}
            setIsUploading={setIsUploading}
            validExtensions={["pdf", "jpg", "png"]}
            isDisabled={!isEditable}
          />
        </Div>
      )
    );
  }

  return (
    <form onSubmit={handleEdit}>
      <div className="displayFlexWrap py-4">
        {condicionGestante()}
        {condicionLactante()}
        {condicionMaternidad()}
        {gestantePreExistencia}
        {lactanteCartaConvenio}
        {condicionDescansoMedico()}
        {dmPreExistencia}
        {formValues[KEYS.idEstadoAprobacion] === ESTADOS.OBSERVADO && (
          <Div col={12}>
            <InputTextArea label="Observación" isDisabled {...commonProps(KEYS.observacion)} direction="column" />
          </Div>
        )}
        {saveButton}
      </div>
      {isEditable && isAdmin && (
        <DatosMaternidadActions
          idCondicion={formValues["IdCondicion"]}
          item={`${formValues["NombreEmpleado"]} - ${formValues["TipoDeCondicion"]}`}
          refreshMainPage={refreshMainPage}
          toggleMainModal={toggleMainModal}
          puedeObservar={formValues.PuedeObservar}
          preExistencia={formValues[KEYS.preExistencia] === "Sí"}
          row={formValues}
        />
      )}
    </form>
  );
}

export default DatosCondicion