import React, { useEffect, useState } from 'react'
import { Div } from './Components'
import InputDate from '../../../../components/modalComponents/InputDate'
import InputSelect from '../../../../components/modalComponents/InputSelect'
import InputTextArea from '../../../../components/modalComponents/InputTextArea'
import InputUploadFile from '../../../../components/modalComponents/InputUploadFile'
import InputText from '../../../../components/modalComponents/InputText'
import { useManagePostRequest } from '../../../../hooks/useManagePostRequest/useManagePostRequest'
import { calculateDaysInBetween, isNullOrUndefinded, parseStringToDate } from '../../../../helper/utils'
import { getListaDocumentosCondicionAPI, ToggleRegistradoEnOfisisAPI } from '../../../../consumers/backendApisUrls'
import CustomAlert from '../../../../components/alerts/CustomAlert'
import { INSTITUCIONES_MEDICAS } from '../../../../constants/hardCodedConstants'
import InputBool from '../../../../components/modalComponents/InputBool'

import {estados} from "../../../../constants/states";

const DescansoMedico = ({
  selectProps,
  commonProps,
  formValues,
  setFormValues,
  KEYS,
  sectionName,
  setIsUploading,
  isDisabled = false,
  dmsAprobadosAcumuladosEsteAnio = 0,
  isAdmin = false,
  idEmpleado,
  isCreate = false
}) => {
  const [executePost] = useManagePostRequest();
  const [listDocuments, setListDocuments] = useState(formValues[KEYS.documentos] || []);

  useEffect(() => {
    if (formValues[KEYS.documentos]) {
      setListDocuments(formValues[KEYS.documentos]);
    }
  }, [formValues]);

  const onChangeInstitucionDescansoMedico = async (res) => {
    selectProps(KEYS.idInstitucionDescansoMedico).onChange(res);
    try {
      const payload = {
        [KEYS.idEmpleado]: idEmpleado,
        [KEYS.idTipoDeCondicion]: formValues[KEYS.idTipoDeCondicion],
        [KEYS.idInstitucionDescansoMedico]: res?.target?.value?.value,
        [KEYS.fechaInicioCondicion]: formValues[KEYS.fechaInicioCondicion],
        [KEYS.fechaFinCondicion]: formValues[KEYS.fechaFinCondicion],
        [KEYS.agregarCartaCompromiso]: formValues[KEYS.tieneSubsidio],
      };
      await getDocuments(payload);
    } catch (error) {
      console.error(error);
    }
  };

  const onChangeSubsidio = async (res) => {
    commonProps(KEYS.tieneSubsidio).onChange(res);
    try {
      const payload = {
        [KEYS.idEmpleado]: idEmpleado,
        [KEYS.idTipoDeCondicion]: formValues[KEYS.idTipoDeCondicion],
        [KEYS.idInstitucionDescansoMedico]: formValues[KEYS.idInstitucionDescansoMedico],
        [KEYS.fechaInicioCondicion]: formValues[KEYS.fechaInicioCondicion],
        [KEYS.fechaFinCondicion]: formValues[KEYS.fechaFinCondicion],
        [KEYS.agregarCartaCompromiso]: res.target.checked,
      };
      await getDocuments(payload);
    } catch (error) {
      console.error(error);
    }
  };

  const onChangeRegistradoEnOfisis = async (res) => {
    commonProps(KEYS.registradoEnOfisis).onChange(res);

    const api = ToggleRegistradoEnOfisisAPI();
    const payload = {"IdCondicion": formValues[KEYS.idCondicion]}
     await executePost(api, payload, successSubmitCallback);



 
  };

  async function getDocuments(payload) {
    const api = getListaDocumentosCondicionAPI();
    const response = await executePost(api, payload, successSubmitCallback);
    let prevDocs = formValues[KEYS.documentos] || [];
    if (response.data) {
      let newDocs = response.data[KEYS.documentos];
      let newState = newDocs.map((doc) => {
        const prevDoc = prevDocs.find((prevDoc) => prevDoc.Discriminator === doc.Discriminator);
        return prevDoc ? prevDoc : doc;
      });
      setListDocuments(newState);
    }
  }

  const onUploadFile = (res, i) => {
    setIsUploading(true);

    const newState = [...listDocuments];
    newState[i].File = res;

    setListDocuments(newState);
    setFormValues((prevState) => {
      const newForm = { ...prevState };
      newForm[KEYS.documentos] = newState;
      return newForm;
    });

    setIsUploading(false);
  };

  const successSubmitCallback = async () => {};

  const institucionDescansoMedico = (
    <Div>
      {isDisabled ? (
        <InputText
          label="Institución emisora"
          name={KEYS.institucionDescansoMedico}
          value={formValues[KEYS.institucionDescansoMedico]}
          sectionName={sectionName}
          isDisabled
        />
      ) : (
        <InputSelect
          label="Institución emisora"
          {...selectProps(KEYS.idInstitucionDescansoMedico)}
          onChange={onChangeInstitucionDescansoMedico}
          sectionName={sectionName}
          isDisabled={isDisabled}
          isRequired={!isDisabled}
        />
      )}
    </Div>
  );

  const tieneSubsidio = !isCreate && (
    <Div>
      <InputBool
        label="Subsidio"
        name={KEYS.tieneSubsidio}
        value={formValues[KEYS.tieneSubsidio]}
        {...commonProps(KEYS.tieneSubsidio)}
        onChange={onChangeSubsidio}
        sectionName={sectionName}
        isDisabled={!isAdmin || isDisabled}
      />
    </Div>
  );

  const registradoEnOfisis = !isCreate && (
    <div className="col-md-12">
      <InputBool
        label="Registrado en Ofisis"
        name={KEYS.registradoEnOfisis}
        value={formValues[KEYS.registradoEnOfisis]}
        {...commonProps(KEYS.registradoEnOfisis)}
        onChange={onChangeRegistradoEnOfisis}
        sectionName={sectionName}
        isDisabled={!isAdmin}
      />
      <hr />
    </div>
  );

  const documentoCanjeDMEssaludCittFirmado = formValues[KEYS.documentoCanjeDMEssaludCittFirmado] && (
    <Div>
      <InputUploadFile
        label="Documento Canje DM Essalud CITT"
        name={KEYS.documentoCanjeDMEssaludCittFirmado}
        uploadFile={formValues[KEYS.documentoCanjeDMEssaludCittFirmado]}
        sectionName={sectionName}
        isDisabled
      />
    </Div>
  );

  const motivoDescansoMedico = (
    <Div>
      {isDisabled ? (
        <InputText
          label="Motivo de descanso"
          name={KEYS.motivoDescansoMedico}
          value={formValues[KEYS.motivoDescansoMedico]}
          sectionName={sectionName}
          isDisabled
        />
      ) : (
        <InputSelect
          label="Motivo de descanso"
          {...selectProps(KEYS.idMotivoDescansoMedico, [
            KEYS.certificadoFileViewModel,
            KEYS.pagoConsultaFileViewModel,
            KEYS.recetaMedicaFileViewModel,
            KEYS.pagoRecetaMedicaFileViewModel,
            KEYS.descansoMedicoCITTFileViewModel,
            KEYS.descansoMedicoFileViewModel,
            KEYS.anexo18FileViewModel,
            KEYS.ultimoInformeEcograficoFileViewModel,
          ])}
          sectionName={sectionName}
          isDisabled={isDisabled}
          isRequired={!isDisabled}
        />
      )}
    </Div>
  );

  function getDays() {
    if (formValues[KEYS.fechaInicioCondicion] && formValues[KEYS.fechaFinCondicion])
      return calculateDaysInBetween(formValues[KEYS.fechaInicioCondicion], formValues[KEYS.fechaFinCondicion]);

    return "-";
  }

  function getDaysCurrentYear() {
    if (formValues[KEYS.fechaInicioCondicion] && formValues[KEYS.fechaFinCondicion])
    {
      let start_Date = parseStringToDate(formValues[KEYS.fechaInicioCondicion]);
      let end_Date = parseStringToDate(formValues[KEYS.fechaFinCondicion]);

      let fechaFinTemp =
                   end_Date.getFullYear() > new Date().getFullYear() ?
                   new Date().getFullYear() +"-12-31":
                   formValues[KEYS.fechaFinCondicion];

      let fechaInicioTemp = start_Date.getFullYear() < new Date().getFullYear() ?
                 new Date().getFullYear() +"-01-01":
                 formValues[KEYS.fechaInicioCondicion];

      let cantidadDias = calculateDaysInBetween(fechaInicioTemp, fechaFinTemp);
      return cantidadDias;
    }
    return 0;
  }



  async function onChangeDates(value, e) {
    commonProps(value).onChange(e);
    try {
      if (formValues[KEYS.idInstitucionDescansoMedico] === INSTITUCIONES_MEDICAS.PARTICULAR) {
        const payload = {
          [KEYS.idEmpleado]: idEmpleado,
          [KEYS.idTipoDeCondicion]: formValues[KEYS.idTipoDeCondicion],
          [KEYS.idInstitucionDescansoMedico]: formValues[KEYS.idInstitucionDescansoMedico],
          [KEYS.fechaInicioCondicion]:
            value === KEYS.fechaInicioCondicion ? e.target.value : formValues[KEYS.fechaInicioCondicion],
          [KEYS.fechaFinCondicion]:
            value === KEYS.fechaFinCondicion ? e.target.value : formValues[KEYS.fechaFinCondicion],
          [KEYS.agregarCartaCompromiso]: formValues[KEYS.tieneSubsidio],
        };
        await getDocuments(payload);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const intervaloDeDescansoMedico = (
    <>
      <Div>
        <InputDate
          label={"Fecha de inicio"}
          name={KEYS.fechaInicioCondicion}
          max={formValues[KEYS.fechaFinCondicion]}
          {...commonProps(KEYS.fechaInicioCondicion, [KEYS.cantidadDias])}
          onChange={(e) => onChangeDates(KEYS.fechaInicioCondicion, e)}
          sectionName={sectionName}
          isDisabled={isDisabled}
          isRequired={!isDisabled}
        />
      </Div>
      <Div>
        <InputDate
          label={"Fecha de fin"}
          name={KEYS.fechaFinCondicion}
          min={formValues[KEYS.fechaInicioCondicion]}
          {...commonProps(KEYS.fechaFinCondicion, [KEYS.cantidadDias])}
          onChange={(e) => onChangeDates(KEYS.fechaFinCondicion, e)}
          sectionName={sectionName}
          isDisabled={isDisabled}
          isRequired={!isDisabled}
        />
      </Div>
      <Div>
        <InputText
          label={"Cantidad de días"}
          name={"Cantidad de días"}
          value={getDays()}
          sectionName={sectionName}
          isDisabled
        />
      </Div>
      {!isCreate && (
        <Div>
          <InputText
            label={"Días DM's aprobados acumulados en el año actual"}
            name={"Días DM's aprobados acumulados en el año actual"}
            value={dmsAprobadosAcumuladosEsteAnio}
            sectionName={sectionName}
            isDisabled
          />
        </Div>
      )}
    </>
  );

  const documentTable = (
    <div className="col-md-12">
      <table className="table table-hover table-bordered">
        <thead className="listar-thead">
          <tr>
            <th className="table-header-bold">Nombre de documento</th>
            <th className="table-header-bold">Plantilla</th>
            <th className="table-header-bold">Documento</th>
          </tr>
        </thead>
        <tbody className="listar-tbody">
          {listDocuments?.length === 0 && (
            <tr>
              <td colSpan="3" className="text-center py-2">
                No se han subido documentos
              </td>
            </tr>
          )}
          {listDocuments?.map((document, i) => (
            <tr key={`${i}-${document.value}`}>
              <td>{document?.NombreDocumento}</td>
              <td className="text-center upload-file-p-small">
                <InputUploadFile
                  name={document?.NombreDocumento}
                  uploadFile={document?.PlantillaDocumento}
                  colInput={12}
                  sectionName={sectionName}
                  isDisabled={true}
                  isIcon={true}
                  NoFileMessage="-"
                />
              </td>
              <td className="text-center upload-file-p-small">
                <InputUploadFile
                  name={document?.NombreDocumento}
                  uploadFile={document?.File}
                  setUploadFile={(res) => onUploadFile(res, i)}
                  colInput={12}
                  sectionName={sectionName}
                  isDisabled={isDisabled}
                  isIcon={true}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const alert20diasDM = formValues[KEYS.dMsAprobadosAcumuladosEsteAnioMayor20Dias] && (
    <div className="col-md-12">
      <CustomAlert variant={"warning"} isDismissible={false}>
        Supera los 20 días de descansos médicos aprobados en el año actual.
      </CustomAlert>
    </div>
  );

  const alertRegistroPendiente = formValues[KEYS.idEstadoAprobacion] === estados.Pendiente.id && !isAdmin && (
    <div className="col-md-12">
      <CustomAlert variant={"warning"} isDismissible={false}>
      Tu solicitud será evaluada los próximos días por el equipo de Bienestar, una vez aprobada te llegará una notificación.
      </CustomAlert>
    </div>
  );

  function alert20diasDMAlCrear() {
    if (formValues[KEYS.idCondicion]) return;
    if (dmsAprobadosAcumuladosEsteAnio >= 20) {
      return (
        <div className="col-md-12">
          <CustomAlert variant={"warning"} isDismissible={false}>
          Supera los 20 días de descansos médicos aprobados en el año actual.
          </CustomAlert>
        </div>
      );
    }
    if (
      //formValues[KEYS.idInstitucionDescansoMedico] == INSTITUCIONES_MEDICAS.PARTICULAR &&
      dmsAprobadosAcumuladosEsteAnio + getDaysCurrentYear() > 20
    ) {
      return (
        <div className="col-md-12">
          <CustomAlert variant={"warning"} isDismissible={false}>
            Con el descanso médico que se está solicitando superarás los 20 días en el año actual.
          </CustomAlert>
        </div>
      );
    }
  }

  function AlertaTieneDescansoMedicoAprobadoEnDiciembreDelAnioPasado() {
    if (formValues[KEYS.tieneSubsidioContinuado] === true){
      return (
      <div className="col-md-12">
        <CustomAlert variant={"warning"} isDismissible={false}>
         El colaborador presenta subsidio continuado desde diciembre.
        </CustomAlert>
      </div>)
    }else {
      return <></>
    }
  }

  const diagnostico = (
    <div className="col-md-12">
      <InputTextArea
        label="Diagnóstico"
        direction="column"
        {...commonProps(KEYS.diagnostico)}
        isDisabled={isDisabled}
        isRequired={!isDisabled}
      />
    </div>
  );

  const comentario = (
    !isCreate && (
    <div className="col-md-12">
      <InputTextArea
        label="Comentario"
        direction="column"
        {...commonProps(KEYS.comentario)}
        isDisabled={isDisabled || (!isAdmin && formValues[KEYS.idEstadoAprobacion] === estados.Observado.id) }
        placeholder={(isDisabled || (!isAdmin && formValues[KEYS.idEstadoAprobacion] === estados.Observado.id)) && (isNullOrUndefinded(formValues[KEYS.comentario]) || formValues[KEYS.comentario] === "" )? "" : "Ingresar Comentario"}
      />
    </div>)
  );

  return (
    <>
      {alertRegistroPendiente}
      {alert20diasDMAlCrear()}
      {alert20diasDM}
      {AlertaTieneDescansoMedicoAprobadoEnDiciembreDelAnioPasado()}
      {registradoEnOfisis}
      {intervaloDeDescansoMedico}
      {motivoDescansoMedico}
      {institucionDescansoMedico}
      {tieneSubsidio}
      
      {documentoCanjeDMEssaludCittFirmado}
      {diagnostico}
      {comentario}
      {documentTable}
    </>
  );
};

export default DescansoMedico
