import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types"
import { Div, MESES_GESTACION } from './components/Components';
import Gestante from './components/Gestante';
import Lactancia from './components/Lactancia';
import ModalForm from '../../../components/modalComponents/ModalForm'
import InputText from '../../../components/modalComponents/InputText';
import InputDate from '../../../components/modalComponents/InputDate';
import InputSelect from '../../../components/modalComponents/InputSelect';
import SpinnerLoading from '../../../components/pageComponents/SpinnerLoading';
import InputUploadFile from '../../../components/modalComponents/InputUploadFile';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import { usePropsInputs } from '../../../hooks/useProps/usePropsInput';
import { usePropsSelect } from '../../../hooks/useProps/usePropsSelect';
import { useGetOptionsSelect } from '../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { DM_CONDICION, MATERNIDAD_CONDICION } from '../../../constants/hardCodedConstants';
import { initialAllOptionPromises, isNullOrUndefinded, toIsoForInput } from '../../../helper/utils';
import { getInfoToCreateCondicionAPI, getInstitucionesDescansoMedicoAPI, getMotivosDescansoMedicoAPI, getTipoDeCondicionAPI, registrarCondicionAPI, getEmpleadosToCreateCondicionAPI } from '../../../consumers/backendApisUrls';
import DescansoMedico from './components/DescansoMedico';

import {useManageGetRequest} from '../../../hooks/useManageGetRequest/useManageGetRequest';
import InputSearchAsync from '../../../components/modalComponents/InputSearchAsync';


const KEYS = {
  idEmpleado: "IdEmpleado",
  idTipoDeCondicion: "IdTipoDeCondicion",
  meses: "MesesGestacion",
  fechaNacimiento: "FechaNacimiento",
  fechaProbable: "FechaProbableDeParto",
  fechaUltMenstruacion: "FechaUltimaMenstruacion",
  nombre: "NombreBebe",
  file: "SustentoFile",
  fechaInicioCondicion: "FechaInicioCondicion",
  fechaFinCondicion: "FechaFinCondicion",
  diagnostico: "Diagnostico",
  idMotivoDescansoMedico: "IdMotivoDescansoMedico",
  motivoDescansoMedico: "MotivoDescansoMedico",
  idInstitucionDescansoMedico: "IdInstitucionDescansoMedico",
  institucionDescansoMedico: "InstitucionDescansoMedico",
  documentos: "Documentos",
};
const KEYS_DROPDOWN = ["IdTipoDeCondicion", "IdMotivoDescansoMedico", "IdInstitucionDescansoMedico"];
const SECTION_NAME = "CrearPopUpGenerico";

const CrearMaternidad = ({ toggleModal, refreshPage, isAdmin, idEmpleado: idEmpleadoProp }) => {
  const [executeManageGetRequest] = useManageGetRequest();

  const [formValues, setFormValues] = useState({});
  const [allDropdowns, setAllDropdowns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [selectProps] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);
  const [getOption] = useGetOptionsSelect();
  const [submitForm] = useSubmitForm();

  const [dataToCreate, setDataToCreate] = useState({});

  const [currentIdEmpleado, setCurrentIdEmpleado] = useState(idEmpleadoProp);
  

  const getDropdowns = async (idEmpleado) => {
    //setIsLoading(true);
    const dropdownsPromises = () => [
      getOption(getTipoDeCondicionAPI(idEmpleado)),
      getOption(getMotivosDescansoMedicoAPI()),
      getOption(getInstitucionesDescansoMedicoAPI())
    ]
    await initialAllOptionPromises( dropdownsPromises, KEYS_DROPDOWN, handleDropdowns );
    //setIsLoading(false);
  }

  const handleDropdowns = (data) => {
    const updatedData = [...data, {key: KEYS.meses, value: MESES_GESTACION}];
    setAllDropdowns(updatedData);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = registrarCondicionAPI();
    const payload = getPayload();
    try {
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successSubmitCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const getPayload = () => {

    const { IdTipoDeCondicion, ...rest } = formValues
    let selectedStates = {};
    if (formValues[KEYS.idTipoDeCondicion] === MATERNIDAD_CONDICION.GESTANTE){
      const { FechaProbableDeParto, MesesGestacion, FechaUltimaMenstruacion, SustentoFile } = rest;
      selectedStates = { FechaProbableDeParto, MesesGestacion, FechaUltimaMenstruacion, SustentoFile };
    }
    if (formValues[KEYS.idTipoDeCondicion] === MATERNIDAD_CONDICION.LACTANCIA){
      const { FechaNacimiento, NombreBebe, SustentoFile } = rest;
      selectedStates = { FechaNacimiento, NombreBebe, SustentoFile };
    }
    if (formValues[KEYS.idTipoDeCondicion] === DM_CONDICION.DESCANSO_MEDICO){
      const {
        FechaInicioCondicion,
        FechaFinCondicion,
        Diagnostico,
        InstitucionEmisora,
        IdMotivoDescansoMedico,
        IdInstitucionDescansoMedico,
        Documentos
      } = rest;
      selectedStates = {
        FechaInicioCondicion,
        FechaFinCondicion,
        Diagnostico,
        InstitucionEmisora,
        IdMotivoDescansoMedico,
        IdInstitucionDescansoMedico,
        Documentos,
      };
    }
    return {
      IdEmpleado: currentIdEmpleado,
      IdTipoDeCondicion,
      ...selectedStates
    }
  }

  const successSubmitCallback = async () => {
    toggleModal();
    await refreshPage();
  }

  function canCreate() {
    return formValues[KEYS.idTipoDeCondicion] === MATERNIDAD_CONDICION.LACTANCIA
      ? dataToCreate?.PuedeCrearLactancia
      : true;
  }

  useEffect(() => {
    getDropdowns(currentIdEmpleado);
    getInfoToCreateFunction(currentIdEmpleado);
  }, [])


  useEffect(() => {
    if (!isNullOrUndefinded(formValues?.IdEmpleado))
    {
      setCurrentIdEmpleado(formValues?.IdEmpleado);
      updateEmpleadoData(formValues?.IdEmpleado);
      setFormValues({...formValues, IdTipoDeCondicion: null})
    }

      
  }, [formValues?.IdEmpleado])

  const updateEmpleadoData = async (idEmpleado) => {
    await getInfoToCreateFunction(idEmpleado);
    await getDropdowns(idEmpleado);
  }

  const getInfoToCreateFunction = async (idEmpleado) => {
    await executeManageGetRequest(getInfoToCreateCondicionAPI(idEmpleado), (res) => setDataToCreate(res.data), false);
  }

  if (isLoading) return <SpinnerLoading />

  const CommonData = (
    <>
      <Div>
        <InputDate
          label="Fec. Reg."
          value={toIsoForInput(new Date())}
          isDisabled
        />
      </Div>
      <Div>
        <InputText
          name="Estado"
          label="Estado"
          value="Pendiente de Aprobación"
          isDisabled
        />
      </Div>
      <Div>
        <InputSelect
          label="Condición"
          isRequired={true}
          {...selectProps(KEYS.idTipoDeCondicion, [
            KEYS.fechaProbable,
            KEYS.fechaUltMenstruacion,
            KEYS.meses,
            KEYS.fechaNacimiento,
            KEYS.nombre,
            KEYS.idMotivoDescansoMedico,
            KEYS.fechaInicioCondicion,
            KEYS.fechaFinCondicion,
            KEYS.institucionDescansoMedico,
            KEYS.diagnostico,
            KEYS.certificadoFileViewModel,
            KEYS.pagoConsultaFileViewModel,
            KEYS.recetaMedicaFileViewModel,
            KEYS.pagoRecetaMedicaFileViewModel,
            KEYS.descansoMedicoCITTFileViewModel,
            KEYS.descansoMedicoFileViewModel,
            KEYS.anexo18FileViewModel,
            KEYS.ultimoInformeEcograficoFileViewModel
          ])}
        />
      </Div>
    </>
  );

  return (
    <ModalForm
      titulo="Nuevo registro de DM o maternidad"
      width="1100px"
      showModal={toggleModal}
      isSubmitting={isSubmitting}
      saveButtonDisabled={isUploading || isSubmitting || !canCreate()}
      onSubmit={handleSubmit}>
        {idEmpleadoProp === 0 &&  
        <Div col={10}>
          <InputSearchAsync typeGet label='Empleado'  api={getEmpleadosToCreateCondicionAPI } {...commonProps('IdEmpleado')} />
        </Div>
      }
      
      <div className="displayFlexWrap">
      
      
        {CommonData}
        {formValues[KEYS.idTipoDeCondicion] ===
          MATERNIDAD_CONDICION.GESTANTE && (
          <Gestante
            commonProps={commonProps}
            selectProps={selectProps}
            isCreate={true}
            keys={KEYS}
          />
        )}
        {formValues[KEYS.idTipoDeCondicion] ===
          MATERNIDAD_CONDICION.LACTANCIA && (
          <Lactancia
            commonProps={commonProps}
            fechaNacimiento={formValues[KEYS.fechaNacimiento]}
            isCreate={true}
            keys={KEYS}
            puedeCrearLactancia={dataToCreate?.PuedeCrearLactancia}
          />
        )}
        {Object.values(MATERNIDAD_CONDICION).includes(
          formValues[KEYS.idTipoDeCondicion]
        ) && (
          <Div>
            {formValues[KEYS.idTipoDeCondicion] ===
              MATERNIDAD_CONDICION.GESTANTE && (
              <span className="required font-size-search-label-text">
                * Se requiere subir la cartilla de control pre natal
              </span>
            )}
            {formValues[KEYS.idTipoDeCondicion] ===
              MATERNIDAD_CONDICION.LACTANCIA && (
              <span className="required font-size-search-label-text">
                * Adjuntar el documento de Acta de Nacimiento o copia de DNI,
                este registro solo se toma para fines de registro de lactancia,
                no significa el pago de asignación familiar
              </span>
            )}
            <InputUploadFile
              label="Sustento"
              name={KEYS.file}
              uploadFile={formValues[KEYS.file]}
              setUploadFile={(res) =>
                setFormValues({ ...formValues, [KEYS.file]: res })
              }
              sectionName={SECTION_NAME}
              setIsUploading={setIsUploading}
              validExtensions={["pdf", "jpg", "png"]}
              isRequired={true}
            />
          </Div>
        )}
        {formValues[KEYS.idTipoDeCondicion] ===
          DM_CONDICION.DESCANSO_MEDICO && (
          <DescansoMedico
            selectProps={selectProps}
            commonProps={commonProps}
            fechaNacimiento={formValues[KEYS.fechaNacimiento]}
            formValues={formValues}
            setFormValues={setFormValues}
            setIsUploading={setIsUploading}
            sectionName={SECTION_NAME}
            KEYS={KEYS}
            dmsAprobadosAcumuladosEsteAnio={dataToCreate?.DMsAprobadosAcumuladosEsteAnio}
            isCreate={true}
            isAdmin={isAdmin}
            idEmpleado={currentIdEmpleado}
          />
        )}
      </div>
    </ModalForm>
  );
}

CrearMaternidad.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  refreshPage: PropTypes.func
}

export default CrearMaternidad;