import React, { useState, useEffect } from 'react';
import ModalEnabled from '../../../../components/modalComponents/ModalEnabled';
import { NUEVOS_INGRESOS_FICHA_STEPS } from '../../../../constants/hardCodedConstants';
import { useManageGetRequest } from '../../../../hooks/useManageGetRequest/useManageGetRequest';
import useDidMountEffect from '../../../../hooks/useDidMountEffect/useDidMountEffect';
import DatosInformacionEconomica from './SectionsEdit/DatosInformacionEconomica';
import DatosFormacionAcademica from './SectionsEdit/DatosFormacionAcademica';
import DatosIdentificacion from './SectionsEdit/DatosIdentificacion';
import DatosResidencia from './SectionsEdit/DatosResidencia';
import DatosNacimiento from './SectionsEdit/DatosNacimiento';
import ListaDocumentos from './ListaDocumentos';
import DatosFamiliares from './SectionsEdit/DatosFamiliares';
import SendModalValidacion from '../modales/SendModalValidacion';
import Stepper from '../globalComponents/Stepper';
import { enviarDocumentosAprobacionNuevoIngresoAPI, getDatosEmpresaGestionAPI } from '../../../../consumers/backendApisUrls';

const DatosPersonalesEditarGlobal = ({ idNuevosIngresosEmpleado, manageSearchActividadesPendientes, manageSearchListaDocumentos, documents, signature, puedeGestionarFirma, isColaborador, isOnlyDocuments = false, isObservated = false, fichaStep = 1, isActividadPendiente = false, widthDatosGlobal = "w-75", initStep = NUEVOS_INGRESOS_FICHA_STEPS.stepOne.id, toggleParentModal, urlInstructivoFirma, tieneHijos = false }) => {

  const ID_PARENT = "editarDatospersonalesConId";
  const PASO_4_FICHA_NUEVOS_INGRESOS = 4;

  const [commonStates, setCommonStates] = useState({});
  const [currentStep, setCurrentStep] = useState(initStep);
  const [sendCurrentStep, setSendCurrentStep] = useState({step: 0, isSent: false});
  const [modalEnable, setModalEnable] = useState({isEnable: false});

  const [executeGet] = useManageGetRequest();

  const commonProps = () => {
    return {
      idNuevosIngresosEmpleado,
      idParent: ID_PARENT,
      isColaborador,
      sendCurrentStep,
      setSendCurrentStep
    }
  }

  const stepComponents = {
    [NUEVOS_INGRESOS_FICHA_STEPS.stepOne.id]: {component: <DatosIdentificacion {...commonProps()} manageSearchListaDocumentos={manageSearchListaDocumentos}/>, name: "Datos de Identificación"},
    [NUEVOS_INGRESOS_FICHA_STEPS.stepTwo.id]: {component: <DatosFormacionAcademica {...commonProps()} commonStates={commonStates}/>, name: "Formación Académica"},
    [NUEVOS_INGRESOS_FICHA_STEPS.stepThree.id]: {component: <DatosNacimiento {...commonProps()}/>, name: "Datos de Nacimiento"},
    [NUEVOS_INGRESOS_FICHA_STEPS.stepFour.id]: {component: <DatosResidencia {...commonProps()}/>, name: "Dirección Actual"},
    [NUEVOS_INGRESOS_FICHA_STEPS.stepFive.id]: {component: <DatosInformacionEconomica {...commonProps()} commonStates={commonStates} manageSearchListaDocumentos={manageSearchListaDocumentos}/>, name: "Información Económica"},
    [NUEVOS_INGRESOS_FICHA_STEPS.stepSix.id]: {component: <DatosFamiliares {...commonProps()}/>, name: "Datos Familiares"},
    [NUEVOS_INGRESOS_FICHA_STEPS.stepSeven.id]: {component: <ListaDocumentos listDocuments={documents} idNuevosIngresosEmpleado={idNuevosIngresosEmpleado} manageSearchListaDocumentos={manageSearchListaDocumentos} signature={signature} puedeGestionarFirma={puedeGestionarFirma} urlInstructivoFirma={urlInstructivoFirma} isColaborador/>, name: "Lista de Documentos"},
  }

  const getDatosEmpresa = async () => {
    const api = getDatosEmpresaGestionAPI(idNuevosIngresosEmpleado);
    return await executeGet(api, successManageSearchCallback)
  }

  const successManageSearchCallback = ({ data }) => {
    let { IdTipoEmpleado, IdTipoTrabajador, IdCargo } = data;
    setCommonStates(lastData => ({...lastData, IdTipoEmpleado, IdTipoTrabajador, IdCargo }));
  }

  const handleClick = (clickType) => {
		let newStep = currentStep;
    if (clickType === "save"){
      setSendCurrentStep({step: currentStep, isSent: true});
    } else {
      validateStep(--newStep);
    }
	}

  const validateStep = (newStep) => {
    if (newStep > 0 && newStep <= Object.keys(NUEVOS_INGRESOS_FICHA_STEPS).length) {
      setCurrentStep(newStep);
    }
  }

  useDidMountEffect(() => {
    if(sendCurrentStep.isSaved && !sendCurrentStep.isSent){
      let newStep = currentStep;
      validateStep(++newStep);
      setSendCurrentStep({step: 0, isSent: false})
    } else {
      setSendCurrentStep({step: currentStep, isSent: false});
    }
  }, [sendCurrentStep.isSaved])

  useEffect(() => {
    getDatosEmpresa();
  }, [])

  let stepsToRender = []
  if (isOnlyDocuments){
    stepsToRender = fichaStep < PASO_4_FICHA_NUEVOS_INGRESOS
      ? [stepComponents[NUEVOS_INGRESOS_FICHA_STEPS.stepSeven.id]]
      : tieneHijos
        ? [stepComponents[NUEVOS_INGRESOS_FICHA_STEPS.stepSix.id], stepComponents[NUEVOS_INGRESOS_FICHA_STEPS.stepSeven.id]]
        : [stepComponents[NUEVOS_INGRESOS_FICHA_STEPS.stepSeven.id]]
  } else {
    if (fichaStep < PASO_4_FICHA_NUEVOS_INGRESOS){
      stepsToRender = Object.entries(stepComponents)
        .filter(([key]) => Number(key) !== Number(NUEVOS_INGRESOS_FICHA_STEPS.stepSix.id))
        .map(([, value]) => value);
    } else {
      stepsToRender = Object.values(stepComponents);
    }
  }

  return (
    <div className={`${widthDatosGlobal} m-auto`}>
      <ModalEnabled modalEnabled={modalEnable} setModalEnabled={setModalEnable} />
      {stepsToRender.length > 1 && 
        <Stepper steps={stepsToRender.map(step => step.name)} currentStepNumber={currentStep} setCurrentStepNumber={setCurrentStep} isObservated={isObservated}/>
      }
      {React.cloneElement(stepsToRender[currentStep - 1].component, { componentStep: currentStep })}
      <div className="d-flex justify-content-around py-4" style={{borderTop: "1px solid #dee2e6"}}>
        {currentStep !== NUEVOS_INGRESOS_FICHA_STEPS.stepOne.id && 
          <button onClick={() => handleClick()} className="btn btn-primary generic-button-ficha-modal"> Atrás </button>
        }
        {currentStep !== stepsToRender.length
          ? <button type='button' onClick={() => handleClick("save")} className="btn generic-button-buscador generic-button-hover-verde" style={{backgroundColor: "#8DBA38"}}>Guardar y Continuar</button>
          : <button type='button' onClick={() => setModalEnable({isEnable: true, component: SendModalValidacion, data: {id: idNuevosIngresosEmpleado, api: enviarDocumentosAprobacionNuevoIngresoAPI, actionLabel: "enviar", name: "Ficha Colaborador", titulo:"Enviar Ficha y Documentos", setModalEnable, isActividadPendiente, manageSearchActividadesPendientes, toggleParentModal }})} className="btn generic-button-buscador generic-button-hover-verde green-innova-background">Guardar y Enviar</button>
        }
      </div>
    </div>
  )
}

export default DatosPersonalesEditarGlobal;