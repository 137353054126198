import React, {useContext, useEffect, useState} from 'react';

import {downloadFile} from "../../consumers/backendConsumer";
import {AuthContext} from "../../hooks/context/authContext";
import Wrapper from "../../components/atoms/DirectorioWrapper";
import PageHeader from "../../components/pageHeader/PageHeader";
import {Dropdown, Spinner} from "react-bootstrap";
import TableWebBody from "./TableWebBody";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import RegistrarCeseModal from "./modales/RegistrarCeseModal";
import RegistrarNotaModal from "./modales/RegistrarNotaModal";
import RechazarCeseModal from "./modales/RechazarCeseModal";
import ConfiguracionCeseModal from "./modales/ConfiguracionCeseModal";
import {toast} from "react-toastify";
import {esArrayVacio, setStateWithCallBackResponse, usuarioLogueadoTieneAlgunoDeLosSiguientesRoles} from "../../helper/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Paginator from "../directorio/Paginator";
import {GENERIC_SUCCESS_TOAST_MESSAGE, ROLES} from "../../constants/hardCodedConstants";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import {
    aprobarCeseAPI,
    deleteFileFromS3API,
    generarReporteCesesAPI,
    getAllCargosAPI,
    getAllCesesAPI,
    getAllDepartamentosAPI, getAllEstadosAPI,
    getAllSedesAPI,
    getAllTiposContratoAPI,
    getCondicionesProcesoCierreAPI,
    getEstadosProcesoCierreAPI,
    getEstadosRegistroCierreAPI
} from "../../consumers/backendApisUrls";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import DetalleRenunciaModal from "./modales/DetalleRenunciaModal";
import ModalInformativoRenuncias from "./modales/ModalInformativoRenuncias";
import FormSelect from "../../components/atoms/Select";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {updateOptionsDeFiltro} from "../../helper/Listas/ListasUtils";
import EditarCeseModal from './modales/EditarCeseModal';
import ModalConfirmarEditar from './modales/ModalConfirmarEditar';

const AdminCeses = () => {
    const {state} = useContext(AuthContext);

    const [executeGetRequest, executeGetRequestCallback] = useGetRequest();


    const [permisosBotones, setPermisosBotones] = useState({});
    const [hideEditarCeseModal, setHideEditarCeseModal] = useState(true);
    const [hideConfirmarEditarCeseModal, setHideConfirmarEditarCeseModal] = useState(true);
    const [datosConfirmar, setDatosConfirmar] = useState();
    const [hideRegistrarCeseModal, setHideRegistrarCeseModal] = useState(true);
    const [hideRegistrarNotaModal, setHideRegistrarNotaModal] = useState(true);
    const [hideRechazarCeseModal, setHideRechazarCeseModal] = useState(true);
    const [hideConfiguracionCeseModal, setHideConfiguracionCeseModal] = useState(true);
    const [hideDetalleRenunciaModal, setHideDetalleRenunciaModal] = useState(true);
    const [hideModalInformativoRenuncia, setHideModalInformativoRenuncia] = useState(true);
    const [idCese, setIdCese] = useState();
    const [nombreColaborador, setNombreColaborador] = useState("");
    const [idsFichaCamposCesesConCheck, setIdsFichaCamposCesesConCheck] = useState([]);
    const [usuariosFiltrados, setUsuariosFiltrados] = useState([]);

    const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");
    const [page, setPage] = useState(1);
    const [executePostRequest] = usePostRequest();
    const [totalItems, setTotalItems] = useState();
    const [firsItemPage, setFirsItemPage] = useState();
    const [lastItemPage, setLastItemPage] = useState();
    const [totalPages, setTotalPages] = useState();
    const [cleanFilterss, setCleanFilters] = useState(false);
    const [fechaDeRegistroMasRecientePrimeroBool, setFechaDeRegistroMasRecientePrimeroBool] = useState(true);
    const [ultimoDiaDeLaboresMasRecientePrimeroBool, setUltimoDiaDeLaboresMasRecientePrimeroBool] = useState(true);

    const [estadosFiltradosOptions, setEstadosFiltradosOptions] = useState([]);
    const [cargosFiltradosOptions, setCargosFiltradosOptions] = useState([]);
    const [sedesFiltradosOptions, setSedesFiltradosOptions] = useState([]);

    const [allEstadosOptions, setAllEstadosOptions] = useState([]);
    const [allCargosOptions, setAllCargosOptions] = useState([]);
    const [allSedesOptions, setAllSedesOptions] = useState([]);

    const [estadosSeleccionados, setEstadosSeleccionados] = useState([]);
    const [cargosSeleccionados, setCargosSeleccionados] = useState([]);
    const [sedesSeleccionados, setSedesSeleccionados] = useState([]);

    const [loading, setLoading] = useState(true);
    const [puedeEditar, setPuedeEditar] = useState(false);
    const [confirmarEdit, setConfirmarEdit] = useState(false);

    const [estadoCeseRegistro, setEstadoCeseRegistro] = useState(null);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            getInitialData();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        setPage(1);
       // manageSearch();
    }, [criterioDeBusqueda, estadosSeleccionados, cargosSeleccionados, sedesSeleccionados]);

    useEffect(() => {
        manageSearch();
    }, [estadosSeleccionados, cargosSeleccionados, sedesSeleccionados, page]);

    useDidMountEffect(() => {
        manageSearch();
    }, [fechaDeRegistroMasRecientePrimeroBool, ultimoDiaDeLaboresMasRecientePrimeroBool]);

    const getInitialData = async () => {
        setLoading(true);
        try {
            await setStateWithCallBackResponse(executeGetRequestCallback(getAllEstadosAPI()), setAllEstadosOptions);
            await setStateWithCallBackResponse(executeGetRequestCallback(getAllCargosAPI()), setAllCargosOptions);
            await setStateWithCallBackResponse(executeGetRequestCallback(getAllSedesAPI()), setAllSedesOptions);
            await manageSearch();
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    const handleKeypress = (e) => {
        if (e.key === 'Enter') {
            manageSearch();
        }
    };

    const manageSearch = async () => {
        let usersFiltered = usuariosFiltrados;
        setUsuariosFiltrados(usersFiltered);

        let payload = {
            "Criteria": criterioDeBusqueda,
            "Page": page,
            "EsFechaRegistroDescendente": fechaDeRegistroMasRecientePrimeroBool,
            "EsUltimoDiaLaboralesDescendente": ultimoDiaDeLaboresMasRecientePrimeroBool,
            "IdsEstados": estadosSeleccionados,
            "IdsCargos": cargosSeleccionados,
            "IdsSedes": sedesSeleccionados
        };

        await executeSearch(payload);
    };

    const executeSearch = async (payload) => {
        const response = await executePostRequest(getAllCesesAPI(), payload);
        if (responseCode200(response)) {
            setUsuariosFiltrados(response.data.Items);
            setTotalItems(response.data.TotalItems);
            setFirsItemPage(response.data.FirsItemPage);
            setLastItemPage(response.data.LastItemPage);
            setTotalPages(response.data.TotalPages);

            let {PuedeRegistrar, PuedeConfigurar, PuedeRegistrarAcciones, PuedeDescargarReporte} = response.data.ExtraData;
            let permisosBotoness = {PuedeRegistrar, PuedeConfigurar, PuedeRegistrarAcciones, PuedeDescargarReporte};
            setPermisosBotones(permisosBotoness);

            setPuedeEditar(response.data.ExtraData.PuedeEditar);
            updateOptionsDeFiltros(response.data.ExtraData);

        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const updateOptionsDeFiltros = function (lists) {
        updateOptionsDeFiltro(allEstadosOptions, lists["IdsEstados"], setEstadosFiltradosOptions, executeGetRequestCallback(getAllEstadosAPI()));
        updateOptionsDeFiltro(allCargosOptions, lists["IdsCargos"], setCargosFiltradosOptions, executeGetRequestCallback(getAllCargosAPI()));
        updateOptionsDeFiltro(allSedesOptions, lists["IdsSedes"], setSedesFiltradosOptions, executeGetRequestCallback(getAllSedesAPI()));
    };

    const onLeftClic = () => {
        const nextPage = Math.max(page - 1, 1);
        setPage(nextPage);
    };

    const onRightClic = () => {
        const nextPage = Math.min(page + 1, totalPages);
        setPage(nextPage);
    };

    const limpiarBusqueda = () => {
        setCriterioDeBusqueda("");
        setCleanFilters(!cleanFilterss);
        setFechaDeRegistroMasRecientePrimeroBool(true);
        setUltimoDiaDeLaboresMasRecientePrimeroBool(true);
        setEstadosSeleccionados([]);
        setCargosSeleccionados([]);
        setSedesSeleccionados([]);
    };

    const showRegistrarCeseModal = async () => {
        let currentState = hideRegistrarCeseModal;
        setHideRegistrarCeseModal(!currentState);
    };

    const showEditarCeseModal = async (idCesee, estado) => {
        setIdCese(idCesee);
        setEstadoCeseRegistro(estado)
        setHideEditarCeseModal(!hideEditarCeseModal);
    };

    const showConfirmarEditarCeseModal = async (idCesee) => {
        setIdCese(idCesee);
        setHideConfirmarEditarCeseModal(!hideConfirmarEditarCeseModal);
    };

    const showRegistrarNotaModal = async (idCesee) => {
        setIdCese(idCesee);
        let currentState = hideRegistrarNotaModal;
        setHideRegistrarNotaModal(!currentState);
    };

    const showRechazarCeseModal = async (idCesee, nombreColaboradorr) => {
        setIdCese(idCesee);
        setNombreColaborador(nombreColaboradorr);
        let currentState = hideRechazarCeseModal;
        setHideRechazarCeseModal(!currentState);
    };

    const aprobarCeses = async () => {
        if (!esArrayVacio(idsFichaCamposCesesConCheck)) {
            for (let idCeseee of idsFichaCamposCesesConCheck) {
                let response = await executePostRequest(aprobarCeseAPI(idCeseee), {});
                if (responseCode200(response)) {
                    toast.success((response?.data?.Message) ? response?.data?.Message : GENERIC_SUCCESS_TOAST_MESSAGE);
                    await manageSearch();
                } else {
                    mostrarMensajeDeErrorConToast(response);
                }
            }
        }
    };

    const showConfiguracionCese = async () => {
        setHideConfiguracionCeseModal(!hideConfiguracionCeseModal);
    };

    const toggleDetalleRenuncia = async (idCesee) => {
        setIdCese(idCesee);
        setHideDetalleRenunciaModal(!hideDetalleRenunciaModal);
    };

    const toggleModalInformativoRenuncia = async () => {
        setHideModalInformativoRenuncia(!hideModalInformativoRenuncia);
    };

    const eliminarArchivoDeS3 = async function (filePath) {
        let deleteFileFromS3Payload = {
            "Path": filePath
        };
        let deleteFileFromS3Response = await executePostRequest(deleteFileFromS3API(), deleteFileFromS3Payload);
        if (!responseCode200(deleteFileFromS3Response)) {
            mostrarMensajeDeErrorConToast(deleteFileFromS3Response);
        }
    };

    const descargarReporteYEliminarArchivoDeS3 = async function (generarReporteResponse) {
        let originalFileName = generarReporteResponse.data.OriginalFileName;
        let filePath = generarReporteResponse.data.FilePath;
        let descargarReporteResponse = await downloadFile(state.token, filePath, originalFileName);
        if (responseCode200(descargarReporteResponse)) {
            await eliminarArchivoDeS3(filePath);
        } else {
            mostrarMensajeDeErrorConToast(descargarReporteResponse);
        }
    };

    const downloadReporteCeses = async () => {
        let generarReportePayload = 
        {
            "Criteria": criterioDeBusqueda,
            "IdsEstados": estadosSeleccionados,
            "IdsCargos": cargosSeleccionados,
            "IdsSedes": sedesSeleccionados
        };
        let generarReporteResponse = await executePostRequest(generarReporteCesesAPI(), generarReportePayload);
        if (responseCode200(generarReporteResponse)) {
            await descargarReporteYEliminarArchivoDeS3(generarReporteResponse);
        } else {
            mostrarMensajeDeErrorConToast(generarReporteResponse);
        }
    };

    const onChangeFechaDeRegistroMasReciente = (e) => {
        setFechaDeRegistroMasRecientePrimeroBool( e.target.checked);
    };

    const onChangeUltimoDiaDeLaboresMasReciente = (e) => {
        setUltimoDiaDeLaboresMasRecientePrimeroBool( e.target.checked);
    };


    if (loading) {
        return (
            <div className="spinner-cargando-container">
                <Spinner animation="grow" variant="primary" size="1sm"/>
            </div>
        );
    }

    return (
        <>
            <div className="message-no-disponible-en-mobile">
                <p>Gestión de Renuncias no está disponible en mobile.</p>
            </div>
            <div className="no-disponible-en-mobile">
                {hideRegistrarCeseModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <RegistrarCeseModal showModal={showRegistrarCeseModal} manageSearch={manageSearch}/>
                </div>
                }
                {hideRegistrarNotaModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <RegistrarNotaModal showModal={showRegistrarNotaModal} idCese={idCese} manageSearch={manageSearch}/>
                </div>
                }
                {hideRechazarCeseModal === false &&
                <div className="modal-contactanos modal-response-ficha-background" >
                    <RechazarCeseModal showModal={showRechazarCeseModal} idCese={idCese} nombreColaborador={nombreColaborador} manageSearch={manageSearch}/>
                </div>
                }
                {hideConfiguracionCeseModal === false &&
                <div className="modal-contactanos modal-response-ficha-background" >
                    <ConfiguracionCeseModal showModal={showConfiguracionCese}/>
                </div>
                }

                {hideDetalleRenunciaModal === false &&
                <div className="modal-contactanos modal-response-ficha-background" >
                    <DetalleRenunciaModal showModal={toggleDetalleRenuncia} idCese={idCese}/>
                </div>
                }

                {hideModalInformativoRenuncia === false &&
                <div className="modal-contactanos modal-response-ficha-background" >
                    <ModalInformativoRenuncias toggleModal={toggleModalInformativoRenuncia} showRegistrarCeseModal={showRegistrarCeseModal} />
                </div>
                }
                {hideEditarCeseModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <EditarCeseModal 
                        showModal={showEditarCeseModal} 
                        manageSearch={manageSearch} 
                        idCese={idCese} 
                        showConfirmarEditarCeseModal={showConfirmarEditarCeseModal}
                        confirmarEdit={confirmarEdit}
                        setDatosConfirmar={setDatosConfirmar}
                    />
                </div>
                }

                {hideConfirmarEditarCeseModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <ModalConfirmarEditar 
                        showModal={showConfirmarEditarCeseModal} 
                        manageSearch={manageSearch} 
                        idCese={idCese}
                        setConfirmarEdit={setConfirmarEdit}
                        datosConfirmar={datosConfirmar}
                        setDatosConfirmar={setDatosConfirmar}
                        setEstadoCeseRegistro={setEstadoCeseRegistro}
                        estadoCeseRegistro={estadoCeseRegistro}
                    />
                </div>
                }

                <Wrapper className='App'>
                    <PageHeader title={"Gestión de Cese"}/>
                    <div className="generic-buscador-container">
                        <div className="filtros-left-section">
                            <input
                                type='text'
                                className='generic-input-criterio-busqueda'
                                placeholder='Buscar por nombre y/o apellidos'
                                value={criterioDeBusqueda}
                                onChange={(e) => {
                                    setCriterioDeBusqueda(e.target.value);
                                }}
                                onKeyPress={handleKeypress}
                            />
                            <button
                                onClick={manageSearch}
                                type='button'
                                className='generic-button-buscador'>
                                Buscar
                            </button>
                            <a className="clean-filters " onClick={limpiarBusqueda}>Limpiar búsqueda</a>
                        </div>
                        <div className="filtros-right-section">
                            {permisosBotones?.PuedeRegistrar &&
                            <button
                                onClick={toggleModalInformativoRenuncia}
                                type='button'
                                className='generic-button-buscador '>
                                Informar Cese
                            </button>
                            }
                        </div>
                    </div>
                    <div className="generic-buscador-container">
                        <div className="filtros-left-section">
                            {permisosBotones?.PuedeRegistrarAcciones &&
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic" className="blue-background-color">
                                    Acciones
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => aprobarCeses()}>Validar Carta</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            }
                        </div>
                        <div className="filtros-right-section">
                            <div className="hand-pointer ml-auto d-flex flex-row justify-content-center align-items-center">
                                {permisosBotones?.PuedeDescargarReporte &&
                                <div className="align-items-center p-2 mx-2" style={{backgroundColor: 'gray', borderRadius: '50%'}} onClick={downloadReporteCeses}>
                                    <FontAwesomeIcon icon='cloud-download-alt' size="lg" className="hand-pointer white" style={{color: 'white'}} title="Descargar Reporte"/>
                                </div>
                                }
                                {permisosBotones?.PuedeConfigurar &&
                                <div className="align-items-center p-2 mx-2" style={{backgroundColor: 'gray', borderRadius: '50%'}} onClick={showConfiguracionCese}>
                                    <FontAwesomeIcon icon='cog' size="lg" className="hand-pointer white" style={{color: 'white'}} title="Configuración de Notificaciones"/>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='pt-1'>
                        <div className="table-scroll">
                        <table className='table table-hover'>
                            <thead className="listar-thead">
                                    <tr className='bg-cl-gris '>
                                        <th rowSpan="2">
                                            <div className='flex font-gothan-medium '>Nombre Completo</div>
                                        </th>
                                        <th className="col-1 pl-1 pr-1">
                                            <FormSelect
                                                options={cargosFiltradosOptions}
                                                description={'Cargo'}
                                                setSelectedOptions={setCargosSeleccionados}
                                                selectedOptions={cargosSeleccionados}
                                            />
                                        </th>
                                        <th className="col-1 pl-1 pr-1">
                                            <FormSelect
                                                options={sedesFiltradosOptions}
                                                description={'Sede'}
                                                setSelectedOptions={setSedesSeleccionados}
                                                selectedOptions={sedesSeleccionados}
                                            />
                                        </th>
                                        <th className="col-1 pl-1 pr-1">
                                            <FormSelect
                                                options={estadosFiltradosOptions}
                                                description={'Estado'}
                                                setSelectedOptions={setEstadosSeleccionados}
                                                selectedOptions={estadosSeleccionados}
                                            />
                                        </th>
                                        <th className="col-1 pl-1 pr-1">
                                            <div className=' font-gothan-medium'>Fecha de registro</div>
                                            <div className="custom-control custom-switch mt-1">
                                                <input className="custom-control-input hand-pointer" type="checkbox" checked={fechaDeRegistroMasRecientePrimeroBool} onChange={onChangeFechaDeRegistroMasReciente} id="fechaDeRegistroMasRecientePrimeroBool"></input>
                                                <label className="custom-control-label hand-pointer blue-standard-color listar-table-mas-reciente-checkbox" htmlFor="fechaDeRegistroMasRecientePrimeroBool">
                                                    Ordenar
                                                </label>
                                            </div>
                                        </th>
                                        <th className="col-1 pl-1 pr-1">
                                            <div className=' font-gothan-medium'>Último día de labores</div>
                                            <div className="custom-control custom-switch mt-1">

                                                <input className="custom-control-input hand-pointer" type="checkbox" checked={ultimoDiaDeLaboresMasRecientePrimeroBool} onChange={onChangeUltimoDiaDeLaboresMasReciente} id="ultimoDiaDeLaboresMasRecientePrimeroBool"></input>
                                                <label className="custom-control-label hand-pointer blue-standard-color listar-table-mas-reciente-checkbox" htmlFor="ultimoDiaDeLaboresMasRecientePrimeroBool">
                                                    Ordenar
                                                </label>
                                            </div>
                                        </th>
                                        <th rowSpan="2">
                                            <div className='flex font-gothan-medium'>Notificaciones</div>
                                        </th>
                                        <th rowSpan="1">
                                            <div className='flex font-gothan-medium'>Reingreso recomendado</div>
                                        </th>
                                        <th rowSpan="2">
                                            <div className='flex font-gothan-medium'>Tipo de Cese</div>
                                        </th>
                                        <th rowSpan="2">
                                            <div className='flex font-gothan-medium'>Motivo de renuncia / rechazo</div>
                                        </th>
                                        <th rowSpan="2">
                                            <div className='flex font-gothan-medium'>Opciones</div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <TableWebBody 
                                        puedeEditar={puedeEditar}
                                        cesesFiltradas={usuariosFiltrados} 
                                        idsFichaCamposCesesConCheck={idsFichaCamposCesesConCheck} 
                                        setIdsFichaCamposCesesConCheck={setIdsFichaCamposCesesConCheck} 
                                        showRegistrarNotaModal={showRegistrarNotaModal} 
                                        showRechazarCeseModal={showRechazarCeseModal} 
                                        permisosBotones={permisosBotones} 
                                        toggleDetalleRenuncia={toggleDetalleRenuncia}
                                        showEditarCeseModal={showEditarCeseModal}
                                    />
                                </table>
                        </div>

                            {totalPages > 0 && (
                                <Paginator totalItems={totalItems}
                                           firsItemPage={firsItemPage}
                                           lastItemPage={lastItemPage}
                                           totalPages={totalPages}
                                           page={page}
                                           onLeftClic={onLeftClic}
                                           onRightClic={onRightClic}/>
                            )}
                    </div>
                </Wrapper>
            </div>
        </>
    )
}

export default AdminCeses;
